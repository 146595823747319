import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TextInput, Button, FlatList, Text, KeyboardAvoidingView, Platform, SafeAreaView } from 'react-native';
import { collection, addDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import { database } from '../componenets/firebase'; // Ensure correct path
import moment from 'moment';
import { useUser } from '../navigation/UserContext'; // Adjust the path as needed

const Chat = ({ mainGameId, TheGameId, senderName }) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const messagesRef = collection(database, 'MainGame', mainGameId, 'TheGame', TheGameId, 'Messages');
    const q = query(messagesRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [mainGameId, TheGameId]);

  const sendMessage = async () => {
    if (input.trim()) {
      await addDoc(collection(database, 'MainGame', mainGameId, 'TheGame', TheGameId, 'Messages'), {
        text: input,
        sender: senderName,
        timestamp: new Date(),
      });
      setInput('');
    }
  };

  const formatTimestamp = (timestamp) => {
    return timestamp ? moment(timestamp.toDate()).format('MMM D, YYYY h:mm a') : '';
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Platform.OS === "ios" ? 64 : 0}
      >
      <FlatList
        style={styles.messageList}
        data={messages}
        renderItem={({ item }) => (
          <View style={styles.messageBubble}>
            <Text style={styles.timestamp}>{formatTimestamp(item.timestamp)}</Text>
            <Text>
              <Text style={styles.senderName}>{item.sender}</Text>
              <Text style={styles.messageText}>: {item.text}</Text>
            </Text>
          </View>
        )}
        keyExtractor={(item) => item.id}
        inverted // Optional: Invert the list to start from the bottom like most chat applications
      />
      <View style={styles.inputContainer}>
        <TextInput 
          style={styles.input} 
          value={input} 
          onChangeText={setInput}
          placeholder="Type your message here..."
        />
        <Button title="Send" onPress={sendMessage} color="#4C7BEE" />
      </View>
      
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};


// Enhanced Chat Component Styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#EDEDED', // Light grey background for contrast
  },
  messageList: {
    flex: 1,
  },
  messageBubble: {
    backgroundColor: '#FFFFFF', // White for better readability
    borderRadius: 15,
    padding: 15,
    marginVertical: 8,
    maxWidth: '75%', // Slightly reduce max width for aesthetic purposes
    alignSelf: 'flex-end', // Align to end to simulate current user messages
    elevation: 1, // Add slight shadow for depth (Android)
    shadowColor: '#000', // Shadow for iOS
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
  },
  timestamp: {
    fontSize: 12,
    color: '#999999', // Lighter text color for timestamps
    marginBottom: 5,
  },
  senderName: {
    fontWeight: 'bold',
    color: '#4C7BEE', // Use theme color for sender name
  },
  messageText: {
    fontSize: 16,
    color: '#333333', // Darker color for better readability
  },
  inputContainer: {
    flexDirection: 'row',
    padding: 8,
    backgroundColor: '#F5F5F5', // Light background for input area
    borderTopWidth: 1,
    borderColor: '#CCCCCC', // Light border to define input area
  },
  input: {
    flex: 1,
    backgroundColor: '#FFFFFF', // White background for the input
    borderColor: '#CCCCCC', // Light grey border
    borderWidth: 1,
    borderRadius: 20,
    padding: 10,
    marginRight: 8,
    fontSize: 16, // Increase font size for better readability
  },
});

export default Chat;
