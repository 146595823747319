import React, { useEffect } from 'react';
import { View, Text, Pressable, StyleSheet, Linking, ImageBackground } from 'react-native';
import { getAuth, signOut } from "firebase/auth";

const DeleteAccount = () => {
  useEffect(() => {
    logoutUser();
  }, []);

  const logoutUser = () => {
    const auth = getAuth(); // Get Firebase authentication instance
    signOut(auth)  // Sign out the current user
      .then(() => {
      //  console.log("User logged out successfully!");
      })
      .catch((error) => {
      //  console.error("Logout failed: ", error);
      });
  };

  const goToHomePage = () => {
    const url = 'https://teamplay.cool/';
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
      //  console.log("Can't open URI: " + url);
      }
    });
  };

  return (
     <ImageBackground
      source={require("../assets/background.jpg")}
      style={styles.backgroundImage}
    >
    <View style={styles.container}>
    <Text style={styles.title}>Delete Your Teamplay Account</Text>
<Text style={styles.instructions}>
  Deleting your Teamplay account is permanent and will remove all your data. 
</Text>
<Text style={styles.instructions}>
  Please follow the steps below to proceed:
</Text>
<Text style={styles.step}>1. Log in to Teamplay and navigate to your Profile.</Text>
<Text style={styles.step}>2. Scroll down to the "Manage Account" section in your profile settings.</Text>
<Text style={styles.step}>3. Click on "Delete Account" and confirm your decision to permanently delete your account.</Text>

      <Pressable style={({ pressed }) => [
          styles.button,
          { opacity: pressed ? 0.7 : 1 }
        ]} onPress={goToHomePage}>
        <Text style={styles.buttonText}>Login to Delete Account</Text>
      </Pressable>
    </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 100, // Keeps content from starting at the very top
    padding: 20,
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#FF6347', // Red color to emphasize the seriousness
  },
  instructions: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    color: '#FFF', // Color changed to white
    fontWeight: 'bold',

  },
  step: {
    fontSize: 20,
    textAlign: 'left',
    marginBottom: 10,
   // fontWeight: 'bold',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black

    color: '#FFF', // Color changed to white
  },
  button: {
    backgroundColor: '#FF6347', // Tomato red, indicating a serious action
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 20,
    borderRadius: 8,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  }
});


export default DeleteAccount;
