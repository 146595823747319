import React from "react";
import { View, Text, StyleSheet } from "react-native";

const KnockOutResults = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>How the Knockout Table Works:</Text>

      <Text style={styles.subtitle}>Initial Setup:</Text>
      <Text style={styles.instruction}>
        - Enter the number of teams you want to participate in the Knockout
        Tournament.
      </Text>
      <Text style={styles.instruction}>
        - Click on the "Generate Knockout Table" button to start New Game.
      </Text>

      <Text style={styles.instruction}>
        - But you can also use the "Show Knockout Table" if there is a game
        currently in progress.
      </Text>

      <Text style={styles.subtitle}>Random Pairings:</Text>
      <Text style={styles.instruction}>
        - Teams are randomly paired against each other for each round.
      </Text>
      <Text style={styles.instruction}>
        - If the number of teams is odd, one team will get a 'Free Pass' to the
        next round.
      </Text>

      <Text style={styles.subtitle}>Free Pass Logic:</Text>
      <Text style={styles.instruction}>
        - The system tries to ensure fairness by keeping track of which teams
        have already received a free pass.
      </Text>
      <Text style={styles.instruction}>
        - Teams that have received a free pass in earlier rounds are less likely
        to get another one in subsequent rounds unless necessary. This ensures a
        more balanced distribution of free passes.
      </Text>

      <Text style={styles.subtitle}>Deciding the Winner:</Text>
      <Text style={styles.instruction}>
        - For each matchup, select the winner by clicking on the team name.
      </Text>
      <Text style={styles.instruction}>
        - Once all winners in a round are decided, the next round matchups are
        generated automatically.
      </Text>

      <Text style={styles.subtitle}>Championship:</Text>
      <Text style={styles.instruction}>
        - The process continues until a single champion emerges. If there's only
        one team left in a round without a matchup, that team is crowned the
        champion!
      </Text>

      <Text style={styles.subtitle}>Starting Over:</Text>
      <Text style={styles.instruction}>
        - You can begin a new tournament anytime by clicking on the "Game Setup" button.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 15,
    marginBottom: 10,
  },
  instruction: {
    fontSize: 16,
    marginBottom: 5,
  },
});

export default KnockOutResults;
