import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  collection,
  query,
  where,
  arrayRemove,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";

import { Avatar } from "react-native-elements";
import useAvatarUri from "./Avatar";
import { database } from "../componenets/firebase";

const PlayerRow = ({ player, game, TheGameId, GameDate, GameIndex }) => {
  const [pvpScore, setPvpScore] = useState(player?.pvpScore || 0);

  useEffect(() => {
    setPvpScore(player?.pvpScore || 0);
  }, [player?.pvpScore]);

  const incrementPvpScore = async () => {
    const newPvpScore = pvpScore + 1;
    const playerDocRef = doc(database, "MainGame", game.id, "TheGame", TheGameId, "Players", player.uid);
  
    const docSnapshot = await getDoc(playerDocRef);
    if (docSnapshot.exists()) {
      await updateDoc(playerDocRef, { pvpScore: newPvpScore });
    } else {
      await setDoc(
        playerDocRef,
        {
          pvpScore: newPvpScore,
          uid: player.uid,
          mainGameId: game.id,
          name: player.name,
          gameDate: GameDate,
          gameIndex: GameIndex,
        },
        { merge: true }
      );
    }
  
    setPvpScore(newPvpScore);
  };

  const decrementPvpScore = async () => {
    if (pvpScore > 0) {
        const newPvpScore = pvpScore - 1;
        const playerDocRef = doc(database, "MainGame", game.id, "TheGame", TheGameId, "Players", player.uid);

        await updateDoc(playerDocRef, { pvpScore: newPvpScore });

        setPvpScore(newPvpScore);
    }
  };

  useEffect(() => {
    const playerDocRef = doc(
      database,
      "MainGame",
      game.id,
      "TheGame",
      TheGameId,
      "Players",
      player.uid
    );
  
    const unsubscribe = onSnapshot(playerDocRef, (docSnapshot) => {
      const data = docSnapshot.data();
      if (data && data.pvpScore) {
        setPvpScore(data.pvpScore);
      }
    });
  
    return () => unsubscribe();
  }, [player.uid]);

  return (
    <View style={styles.playerRow}>
      <View style={styles.playerInfo}>
        {player.avatarUri ? (
          <Avatar
            size="small"
            rounded
            source={{ uri: player.avatarUri }}
          />
        ) : (
          <Avatar
            size="small"
            rounded
            title={player.name.slice(0, 2).toUpperCase()}
            overlayContainerStyle={{ backgroundColor: "#bcbec1" }}
          />
        )}
        <View style={styles.nameAndPvp}>
          <Text style={styles.playerName}>{player.name}</Text>
          <Text style={styles.pvpScore}> PVP Score: {pvpScore}</Text>
        </View>
      </View>
      <View style={styles.buttonsContainer}>
        <TouchableOpacity
          style={styles.button}
          onPress={incrementPvpScore}
        >
          <Text style={styles.buttonText}>+</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={decrementPvpScore}
        >
          <Text style={styles.buttonText}>-</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

 
const Pvp = ({ route, navigation }) => {
  const [mainGame, setMainGame] = useState(route.params.mainGameId);
  const [TheGameId, setTheGameId] = useState(route.params.TheGameId);
  const [GameDate, setGameDate] = useState(route.params.GameDate);
  const [GameIndex, setGameIndex] = useState(route.params.GameIndex);
  const [games, setGames] = useState([]);
  const fetchAvatarUri = useAvatarUri();

  useEffect(() => {
    const colRef = collection(database, "MainGame");
    const q = query(colRef, where("MainGameId", "==", mainGame));

    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const fetchedData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const gameData = doc.data();

            const memberOfGame = await Promise.all(
              gameData.memberOfGame.map(async (player) => {
                const avatarUri = await fetchAvatarUri(player.uid);
                return { ...player, avatarUri };
              })
            );

            return { ...gameData, memberOfGame, id: doc.id };
          })
        );

        setGames(fetchedData);
      },
      (error) => {
        console.error("Firebase query error:", error);
      }
    );

    return () => unsubscribe();
  },
  [mainGame]);

  return (
    <SafeAreaView style={styles.container}>
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          navigation.navigate("PvpLeaderBoard", {
            mainGameId: mainGame,
            TheGameId: TheGameId,
          });
        }}
      >
        <Text style={styles.buttonText}>PvP Leaderboard (press button) </Text>
      </TouchableOpacity>

      <FlatList
        data={games}
        keyExtractor={(item) => item.id}
        renderItem={({ item: game }) => {
          return (
            <FlatList
              data={game.memberOfGame}
              keyExtractor={(player, index) => player.uid + index.toString()}
              renderItem={({ item: player }) => (
                player && (
                  <PlayerRow player={player} game={game} TheGameId={TheGameId} GameDate={GameDate} GameIndex={GameIndex}  />
                )
              )}
            />
          );
        }}
      />
    </SafeAreaView>
  );
};

export default Pvp;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 10,
      backgroundColor: "#f4f4f4",
    },
    gameName: {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 10,
      color: "#fd5c63",
    },
    playerRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
      padding: 15,
      backgroundColor: "#fff",
      borderRadius: 10,
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    playerInfo: {
      flexDirection: "row",
      alignItems: "center",
    },
    playerName: {
      fontSize: 16,
      color: "#323232",
      marginRight: 10,
    },
    pvpCount: {
      fontSize: 16,
      color: "#0000FF",
    },
    buttonsContainer: {
      flexDirection: "row",
    },
    button: {
      backgroundColor: "#fd5c63",
      padding: 5,
      paddingHorizontal: 10,
      borderRadius: 10,
    },
    buttonText: {
      fontSize: 20,
      color: "#fff",
    },
  });

