// add player screen - þarf að uppfæra email á alla leiki fyrir viðkomandi leikmann.... 

import React, { useState, useEffect  } from 'react';
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Switch,  
  Platform  
} from 'react-native'; 
import { getDoc, doc, onSnapshot, updateDoc, getFirestore, deleteDoc, collection, getDocs, writeBatch,query,where  } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

const AddPlayerScreen = ({ route }) => {
  const [playerName, setPlayerName] = useState('');
  const [email, setEmail] = useState('');
  const [players, setPlayers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPlayerId, setEditingPlayerId] = useState(null);
  const database = getFirestore();
  const currentMainGameId = route.params.mainGameId.toString();
  const [errorMessage, setErrorMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [verified, setVerified] = useState(false); // State to track verification status
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [showWebAlert, setShowWebAlert] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const auth = getAuth();

  const handleToggleInvite = async (playerUid) => {
    const player = players.find((p) => p.uid === playerUid);

    try {
      //const response = await fetch('https://us-central1-emailtrigger-66e3f.cloudfunctions.net/sendEmail', {
      const response = await fetch(
        "https://us-central1-quizapp-784c4.cloudfunctions.net/sendEmailAPI",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: player.email,
            name: player.name,
            //roomId: roomId,
          }),
        }
      );

      const data = await response.json();

   //   console.log("Response data:", data); // Log the response data

      if (data && data.success) {
        // Update the isInvited status in the database
        const firestore = getFirestore();
        const docRef = doc(firestore, "MainGame", currentMainGameId);
  
        // Retrieve the current game document
        const gameSnap = await getDoc(docRef);
        if (gameSnap.exists()) {
          let updatedMembers = gameSnap.data().memberOfGame.map((p) => {
            if (p.uid === player.uid) {
              return { ...p, isInvited: true, role: "invited" };
            }
            return p;
          });
  
          // Update the memberOfGame array with the updated player information
          await updateDoc(docRef, { memberOfGame: updatedMembers });
  
          Alert.alert("Success", "Email sent successfully and player updated in database!");
        }
      } else {
        Alert.alert("Error", "Failed to send the email.");
      }
    } catch (error) {
      console.error("There was an error sending the email or updating the database:", error);
      Alert.alert("Error", "There was an error sending the email or updating the database.");
    }
  };

    // Assume fetchCurrentUserRole is a function that checks the current user's role
    useEffect(() => {
      const currentUserId = auth.currentUser ? auth.currentUser.uid : "";
      const docRef = doc(database, "MainGame", currentMainGameId);
    
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const gameData = docSnap.data();
    
          // Determine if the current user is an admin or the owner
          const currentPlayer = gameData.memberOfGame.find(
            (player) => player.uid === currentUserId
          );
          if (currentPlayer) {
            setIsAdmin(!!currentPlayer.isAdmin); // Directly convert truthy/falsy to boolean
            setVerified(currentPlayer.role === 'verified'); // Update verification status
            setIsOwner(!!currentPlayer.isOwner); // Check if the current user is the owner
          } else {
            setIsAdmin(false); // Not an admin or player not found
            setVerified(false); // Assume not verified if player not found
            setIsOwner(false); // Assume not owner if player not found
          }
        }
      });
    
      return () => unsubscribe(); // Clean up subscription on component unmount
    }, [currentMainGameId]); // Dependency array includes currentMainGameId to re-run the effect if it changes
    

  useEffect(() => {
    const docRef = doc(database, 'MainGame', currentMainGameId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setPlayers(docSnap.data().memberOfGame || []);
      }
    });
    return () => unsubscribe();
  }, [currentMainGameId]);

  
// Inside your AddPlayerScreen component
const immediateRemovePlayer = async (playerUid) => {
  const removePlayer = async () => {
  //  console.log("Removing player immediately:", playerUid);

    // Filter out the player from the local state
    const updatedPlayers = players.filter((player) => player.uid !== playerUid);
    setPlayers(updatedPlayers);

    try {
      // Update the memberOfGame array in the MainGame document
      const firestore = getFirestore();
      const docRef = doc(firestore, "MainGame", currentMainGameId);
      await updateDoc(docRef, { memberOfGame: updatedPlayers });
   //   console.log("Player removed from memberOfGame successfully");

      // Now, remove the player from the 'Players' Subcollection for each game
      const theGameCollectionRef = collection(firestore, "MainGame", currentMainGameId, "TheGame");
      const gameDocsSnapshot = await getDocs(theGameCollectionRef);
      gameDocsSnapshot.forEach(async (gameDoc) => {
        const playersSubcollectionRef = collection(firestore, "MainGame", currentMainGameId, "TheGame", gameDoc.id, "Players");
        const playerDocsSnapshot = await getDocs(playersSubcollectionRef);
        playerDocsSnapshot.forEach(async (playerDoc) => {
          if (playerDoc.data().userId === playerUid) { // Assuming the player UID is stored in the userId field
            await deleteDoc(doc(firestore, "MainGame", currentMainGameId, "TheGame", gameDoc.id, "Players", playerDoc.id));
       //     console.log(`Player ${playerUid} removed from Players subcollection for game ${gameDoc.id}`);
          }
        });
      });
    } catch (error) {
      console.error("Error removing player from database and subcollections:", error);
    }
  };

  // Confirm removal with the user before proceeding (platform-specific logic remains unchanged)
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    Alert.alert("Remove Player", "Are you sure you want to remove this player?", [{ text: "Cancel", style: "cancel" }, { text: "OK", onPress: removePlayer }], { cancelable: true });
  } else if (Platform.OS === 'web') {
    if (window.confirm("Are you sure you want to remove this player?")) {
      removePlayer();
    }
  } else {
    removePlayer();
  }
};

  
const handleDeleteConfirmation1 = async () => {
  // Function to execute deletion logic
  const executeDeletion = async () => {
    try {
      const batch = writeBatch(database);
      const theGameCollectionRef = collection(database, "MainGame", currentMainGameId, "TheGame");
      const theGameSnapshot = await getDocs(theGameCollectionRef);

      theGameSnapshot.docs.forEach((docSnapshot) => {
        batch.delete(docSnapshot.ref);
      });

      await batch.commit();
     // console.log("Subcollection documents deleted");

      const docRef = doc(database, "MainGame", currentMainGameId);
      await deleteDoc(docRef);
    //  console.log("Main document successfully deleted");

      navigation.navigate("My Games");
    } catch (error) {
      console.error("Error deleting document and its subcollections:", error);
    }
  };

  // Platform-specific confirmation
  if (Platform.OS === "web") {
    // Web platform: Use window.confirm
    if (window.confirm("Are you sure you want to delete this game? This action cannot be undone.")) {
      executeDeletion();
    }
  } else {
    // iOS and other platforms: Use Alert.alert
    Alert.alert(
      "Confirm Deletion",
      "Are you sure you want to delete this game? This action cannot be undone.",
      [
        { text: "Cancel", onPress: () => console.log("Deletion cancelled"), style: "cancel" },
        { text: "Delete", onPress: () => executeDeletion(), style: "destructive" }
      ],
      { cancelable: false }
    );
  }
};



const handleAddOrEditPlayer = async () => {
  if (!playerName.trim()) {
    setErrorMessage('Player name cannot be blank.');
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (email.trim() && !emailRegex.test(email)) {
    setErrorMessage('Please enter a valid email address or leave it blank.');
    return;
  }

  // Check if the email is already in use within the current game
  if (email.trim()) {
    const emailInUse = players.some(player => player.email === email && player.uid !== editingPlayerId);
    if (emailInUse) {
      setErrorMessage('This email is already in use by another player in this game.');
      return;
    }
  }

    // Check for duplicate unverified names within the current game
    const nameInUseByUnverified = players.some(player => player.name === playerName && player.role !== 'verified' && player.uid !== editingPlayerId);
    if (nameInUseByUnverified) {
      setErrorMessage('This name is already in use by an unverified player in this game.');
      return;
    }

  // Reset error message and proceed with updating or adding player
  setErrorMessage('');

  // Check if the email exists in the "users" collection
  let userId = null;
  let fetchedName = null; // To store name from the user document
  let verifiedStatus = 'Not Verified'; // Default verified status
  if (email.trim()) {
    try {
      const userCollectionRef = collection(database, 'users');
      const querySnapshot = await getDocs(query(userCollectionRef, where('email', '==', email)));

      if (!querySnapshot.empty) {
        // Email exists, get the userId and name
        userId = querySnapshot.docs[0].id;  // Use this userId as the uid in TheGame
        fetchedName = querySnapshot.docs[0].data().name; // Assuming 'name' field exists in user document
        verifiedStatus = 'verified'; // Set the verified status to "Verified"
      }
    } catch (error) {
      console.error('Error checking email in the user collection:', error);
      setErrorMessage('Error checking email in the user collection.');
      return;
    }
  }

  let updatedPlayers = [...players];
  let playerToUpdate = null; // Store the player being updated

  if (isEditing) {
    updatedPlayers = updatedPlayers.map(player => {
      if (player.uid === editingPlayerId) {
        playerToUpdate = { 
          ...player, 
          name: fetchedName || playerName, // Use fetched name if available, otherwise use entered name
          email: email, 
          uid: userId || player.uid, // Use userId if available, otherwise keep the existing uid
          role: verifiedStatus // Set the verified status
        };
        return playerToUpdate;
      }
      return player;
    });
  } else {
    playerToUpdate = { 
      uid: userId || Date.now().toString(), // Use userId if available, otherwise generate a new uid
      name: fetchedName || playerName, // Use fetched name if available, otherwise use entered name
      email: email, 
      isInvited: false, 
      isAdmin: false,
      role: verifiedStatus // Set the verified status
    };
    updatedPlayers.push(playerToUpdate);
  }

  const docRef = doc(database, 'MainGame', currentMainGameId);
  await updateDoc(docRef, {
    memberOfGame: updatedPlayers,
  });

  // Add oldUid to the "users" collection if it's not already there
  if (userId) {
    const userDocRef = doc(database, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists() && !userDocSnap.data().oldUid) {
      // Store the oldUid in the "users" collection only if it doesn't already exist
      await updateDoc(userDocRef, {
        oldUid: editingPlayerId // Assuming editingPlayerId is the old UID
      }).then(() => {
        console.log(`Successfully added oldUid ${editingPlayerId} to users collection for userId ${userId}`);
      }).catch((error) => {
        console.error(`Failed to update users collection with oldUid: ${error}`);
      });
    } else {
      console.log(`oldUid already exists for userId ${userId}, no update necessary.`);
    }
  }

  // After updating the main document, update the relevant 'Players' subcollection in 'TheGame'
  if (playerToUpdate) {
    const firestore = getFirestore();
    const theGameRef = collection(firestore, "MainGame", currentMainGameId, "TheGame");
    const theGameSnapshot = await getDocs(theGameRef);

    for (const theGameDoc of theGameSnapshot.docs) {
      const playersRef = collection(firestore, "MainGame", currentMainGameId, "TheGame", theGameDoc.id, "Players");
      const playersSnapshot = await getDocs(playersRef);

      for (const playerDoc of playersSnapshot.docs) {
        const playerData = playerDoc.data();

        // Match based on the old userId instead of uid
        if (playerData.userId === (editingPlayerId || playerToUpdate.uid)) {
          const playerDocRef = doc(firestore, "MainGame", currentMainGameId, "TheGame", theGameDoc.id, "Players", playerDoc.id);

          // Store the current userId as oldUid before updating
          const oldUid = playerData.userId;

          await updateDoc(playerDocRef, {
            userId: playerToUpdate.uid, // Keep the userId field consistent
            Name: playerToUpdate.name, // Update the Name field
            email: playerToUpdate.email, // Update the email field
            oldUid: oldUid, // Store the old UID in the document
          }).then(() => {
            console.log(`Successfully updated player ${playerToUpdate.name} with new userId ${playerToUpdate.uid} and stored oldUid ${oldUid}`);
          }).catch((error) => {
            console.error(`Failed to update player document: ${error}`);
          });
        }
      }
    }
  }

  // Reset form fields after updating
  resetForm();
};











  

  const selectForEdit = (player) => {
    setPlayerName(player.name);
    setEmail(player.email);
    setIsEditing(true);
    setEditingPlayerId(player.uid);
  };

  const resetForm = () => {
    setPlayerName('');
    setEmail('');
    setIsEditing(false);
    setEditingPlayerId(null);
  };

  const renderPlayerItem = ({ item }) => (
    <>
      <View style={styles.playerItem}>
        <Text style={[styles.nameCell ] }>{item.name}</Text>
        <Text style={styles.emailCell}>{item.email}</Text>



        <View style={styles.roleSwitchContainer}>
          <Switch
            trackColor={{ false: "#767577", true: "#81b0ff" }}
            thumbColor={item.isAdmin ? "#f5dd4b" : "#f4f3f4"}
            onValueChange={() => togglePlayerRole(item.uid)}
            value={item.isAdmin}
          />
          <Text style= {{ paddingLeft: 5 }} >{item.isAdmin ? 'Admin' : 'Player'}</Text>
        </View>


        <TouchableOpacity style={styles.editButton} onPress={() => selectForEdit(item)}>
          <Text>Edit</Text>
        </TouchableOpacity>

        <View style={styles.verifiedStatus}>
        <Text>{item.role === 'verified' ? 'Verified' : 'Not Verified'}</Text>
      </View>


        <TouchableOpacity
          style={[styles.inviteCell, { backgroundColor: item.isInvited ? '#90EE90' : '#D3D3D3' }]}
    onPress={() => handleToggleInvite(item.uid)}         >
    <Text>{item.isInvited ? 'Invite' : 'Invite'}</Text>
        </TouchableOpacity>

        {isAdmin && (
        <TouchableOpacity
          style={styles.removeButton}
          onPress={() => immediateRemovePlayer(item.uid)}
        >
          <Text >Remove</Text>
        </TouchableOpacity>
      )}

      </View>
      {/* <View style={styles.lineStyle}></View>   */}
    </>
  );
  
  
  const togglePlayerRole = async (uid) => {
    const updatedPlayers = players.map(player =>
      player.uid === uid ? { ...player, isAdmin: !player.isAdmin } : player
    );
  
    setPlayers(updatedPlayers);
    const docRef = doc(database, 'MainGame', currentMainGameId);
    await updateDoc(docRef, {
      memberOfGame: updatedPlayers,
    });
  };
   

  const renderHeader = () => (
    <View style={styles.playerItem}>
    <Text style={[styles.nameCell, { fontWeight: 'bold'} ]}>Name</Text>
      <Text style={[styles.emailCell, { fontWeight: 'bold'} ]}>Email</Text>
      <Text style={[styles.roleSwitchContainer, { fontWeight: 'bold'} ]}>Role </Text>
      <Text style={[styles.editButton, { backgroundColor:'#FFFFFF', fontWeight: 'bold'}]}>Edit</Text>      
      <Text style={[styles.verifiedStatus, { fontWeight: 'bold'} ]}>Verified</Text>      
      <Text style={[styles.inviteCell,  { fontWeight: 'bold'} ]}>Invite</Text>
      {isAdmin && (  <Text style={[styles.removeButton,  { backgroundColor:'#FFFFFF', fontWeight: 'bold' } ]}>Remove</Text>   )}
    </View>
    
  );


  return (
    <View style={styles.container}> 
{
  isOwner && ( // Only render this button if isOwner is true
    <TouchableOpacity style={styles.deleteButton} onPress={() => handleDeleteConfirmation1("delete")}>
      <Text style={styles.deleteButtonText}>Delete Game</Text>
    </TouchableOpacity>
  )
}



   <TextInput
   style={[styles.input, isEditing && styles.highlightedInput]}
  placeholder="Player Name"
  value={playerName}
  onChangeText={setPlayerName}
 // editable={!verified}
 // onFocus={() => setIsNameFocused(true)}
 // onBlur={() => setIsNameFocused(false)}
/>

{verified && isNameFocused && (
  <Text style={styles.warningMessage}>
    Name cannot be changed because account is verified. Only player can change it.
  </Text>
)}

      <TextInput
      style={[styles.input, isEditing && styles.highlightedInput]}
        placeholder="Email - Optional"
        value={email}
        onChangeText={setEmail}
      />
      {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}


      <Button title={isEditing ? "Save Player" : "Add Player"} onPress={handleAddOrEditPlayer} />

      <View style={styles.listContainer}>
        <ScrollView horizontal={true} contentContainerStyle={styles.scrollViewContent}>
          <View style={styles.innerScrollViewContent}>
            <FlatList
              ListHeaderComponent={renderHeader}
              data={players}
              renderItem={renderPlayerItem}
              keyExtractor={(item) => item.uid.toString()}
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

 
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#FFFFFF',
   // backgroundColor: 'pink',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
  },
  highlightedInput: {
    borderColor: 'orange', // Highlight color
    borderWidth: 2,      // Make border thicker to emphasize
  },
  playerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%', // Ensure each item stretches
    backgroundColor: '#FFFFFF',
    borderWidth : 1,
    borderColor : 'gray',
  },

  nameCell: {
    //flex: 1,
    minWidth: 150,
   // paddingLeft: 1,
     //justifyContent: "center", // Top the text horizontally
    alignSelf: 'center', 
  }, 
  
  emailCell: {
    flex: 2,
    margin: 5,
    //padding: 5,
    //borderColor: 'black',
    //borderWidth: 1,
    //textAlign: 'center',
   // backgroundColor: 'orange',
  },
 
  editButton: {
   // marginRight: 5,
    marginLeft: 5,
   // margin: 5,
    borderRadius: 5,
    backgroundColor: '#ADD8E6',
    width: 50, // Adjust th
    alignItems: 'center',
    textAlign: 'center', // Center text horizontally in its container
   // flex: 0.5,
  },

  
  verifiedStatus: {
    // marginRight: 5,
     marginLeft: 5,
    // margin: 5,
     borderRadius: 5,
     //backgroundColor: '#ADD8E6',
     width: 80, // Adjust th
     alignItems: 'center',
     textAlign: 'center', // Center text horizontally in its container
    // flex: 0.5,
   },



  otherCell: {
    //margin: 10,
    borderRadius: 5,
   // flex: 0.5,
  },

  inviteCell: {
  // margin: 10,
  width: 50, // Adjust th
  alignItems: 'center',
  textAlign: 'center', // Center text horizontally in its container
    marginHorizontal: 10, // Increase space on the sides    borderRadius: 5,
    borderRadius: 5,
   // backgroundColor: 'gold',    
   // flex: 0.5,
  

  },
 

  listContainer: {
    flex: 1, // Allows the list to fill the remaining space
  },


  scrollViewContent: {
    flexGrow: 1, // Ensures the content grows to fit
  },
  innerScrollViewContent: {
    flexDirection: 'row', // Ensures items are laid out horizontally
    minWidth: '100%', // Ensures the view attempts to fill the screen width
  },

 
  // nameDetail: {
  // //  flex: 1, // Adjust this value as needed or use width: <desired width>
  // },

 
  roleSwitchContainer: {
    flexDirection: 'row',
    textAlign: 'center', // Center text horizontally in its container
    width: 100, // Adjust the width as needed
    //paddingLeft: 5,
   // backgroundColor: 'green',
    //padding: 50,
  },




  removeButton: {
  //  padding: 5,
    borderRadius: 5,
    backgroundColor: '#FF6347', // Tomato color for a delete button
  },


  // lineStyle: {
  //   borderBottomColor: 'black', // Line color
  //   borderBottomWidth: 1, // Line thickness
  //   marginLeft: 10,
  //   marginRight: 10,
  // },

  warningMessage: {
    color: 'red',
    marginTop: 5,
  },

  // Add to your StyleSheet
deleteButton: {
  backgroundColor: '#FF6347', // Tomato color
  padding: 10,
  borderRadius: 5,
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 20,
},
deleteButtonText: {
  color: '#FFFFFF',
  fontSize: 16,
  fontWeight: 'bold',
},




 
});

export default AddPlayerScreen;
