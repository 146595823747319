// sort name and sort rank works

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  Pressable,
  ScrollView,
  ActivityIndicator,
  TextInput,
  Alert,
  Platform 
} from "react-native";
import { database, auth  } from "../componenets/firebase";
import {
  collectionGroup,
  query,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  where,
  writeBatch,
  collection,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import PointSystemModal from './ModalPointSystem'; // Adjust the path if needed
import { MaterialIcons } from "@expo/vector-icons"; // Import MaterialIcons from Expo



//import { LinearGradient } from 'expo-linear-gradient'; // For background gradient (Expo's library)

const Results = ({ navigation, route }) => {
  const { mainGame, mainGame : mainGameId, TheGameId, gameDate } = route.params;
  const [playerData, setPlayerData] = useState([]);
  const [gameSettings, setGameSettings] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [sortBy, setSortBy] = useState("Rank"); // State to track sorting criteria (default is Name)
  const originalData = useRef([]); // Store original data for sorting, using useRef to preserve data between renders
  const [scoreHome, setScoreHome] = useState(""); // For Home team score
  const [scoreAway, setScoreAway] = useState(""); // For Away team score
  const [teamSelections, setTeamSelections] = useState({}); // Store team selection per userId
  const [modalVisible, setModalVisible] = useState(false); // Modal state

  const homeCount = playerData.filter(player => teamSelections[player.userId] === "Home").length;
  const awayCount = playerData.filter(player => teamSelections[player.userId] === "Away").length;
  const absentCount = playerData.filter(player => teamSelections[player.userId] === "Absent").length;
  const [isAdmin, setIsAdmin] = useState(false); // Add isAdmin state

  

  //const [pointsEnabled, setPointsEnabled] = useState(false);

  // Define handleTeamSelection here, inside the Results component
  const handleTeamSelection = async (userId, team) => {
    // Update the local state
    setTeamSelections((prev) => ({
      ...prev,
      [userId]: team,
    }));
  
    try {
      // Query Firestore to find the document for the given userId
      const playersRef = collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players");
      const querySnapshot = await getDocs(query(playersRef, where("userId", "==", userId)));
  
      if (!querySnapshot.empty) {
        // If a document with the userId exists, update it
        const playerDoc = querySnapshot.docs[0];
        const playerRef = playerDoc.ref;
  
        // Update the document with the new team selection
        await updateDoc(playerRef, { team: team });
        console.log(`Successfully updated team selection for userId: ${userId}`);
      } else {
        // If no document is found, log a message
        console.log(`No document found for userId: ${userId}. Skipping update.`);
      }
    } catch (error) {
      console.error("Error updating team selection in Firestore:", error);
    }
  };
  

  // UseEffects

  useEffect(() => {
    const fetchIsAdminStatus = async () => {
      try {
        const mainGameDoc = doc(database, "MainGame", mainGameId);
        const mainGameSnapshot = await getDoc(mainGameDoc);
        const { memberOfGame } = mainGameSnapshot.data();

        const currentUser = auth.currentUser;

        if (currentUser) {
          const currentUserId = currentUser.uid;
          const currentUserData = memberOfGame.find(
            (member) => member.uid === currentUserId
          );

          if (currentUserData) {
            const adminStatus = currentUserData.isAdmin;
            setIsAdmin(adminStatus === true);
          }
        }
      } catch (error) {
        console.error("Error in fetchIsAdminStatus:", error);
      }
    };

    fetchIsAdminStatus();
  }, [mainGameId]);

   // Fetch game Name
   useEffect(() => {
    const fetchGameSettings = async () => {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);
      if (gameSettingsSnap.exists()) {
        const settingsData = gameSettingsSnap.data();
        console.log("Fetched Game Settings:", settingsData); // Add this log
        setGameSettings(settingsData);
      } else {
        console.log("No such document for game settings!");
      }
    };
    fetchGameSettings();
  }, [mainGameId]);
  

  // update score in leaderboard
  useEffect(() => {
    const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  
    const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const gameData = docSnapshot.data();
        setScoreHome(gameData.scoreHome || 0); // Update home score
        setScoreAway(gameData.scoreAway || 0); // Update away score
      }
    });
  
    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [mainGameId, TheGameId]);
  

  // Fetch game points settings
  // Fetch game settings / points and log them
useEffect(() => {
  const fetchGameSettings = async () => {
    try {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);

      if (gameSettingsSnap.exists()) {
        const settingsData = gameSettingsSnap.data();
        setGameSettings(settingsData);
        
      
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching game settings:", error);
    }
  };

  fetchGameSettings();
}, [mainGameId, TheGameId]); // Dependencies for useEffect



useEffect(() => {
  const fetchGameDetails = async () => {
    try {
      // Fetch game settings and score
      const gameSettingsRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);
      if (gameSettingsSnap.exists()) {
        const gameData = gameSettingsSnap.data();
        setScoreHome(gameData.scoreHome || 0);  // Fetch and set home score
        setScoreAway(gameData.scoreAway || 0);  // Fetch and set away score
        console.log(`Fetched scores: Home - ${gameData.scoreHome}, Away - ${gameData.scoreAway}`);
      }

      // Fetch team selections and mixPoints
      const playersRef = collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players");
      const currentGameSnapshot = await getDocs(playersRef);

      let selections = {};
      let playerMixPoints = {}; // Store mixPoints for each player
      currentGameSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        selections[data.userId] = data.team || null;  // Set team from Firestore or null if not set
        playerMixPoints[data.userId] = data.mixPoints || 0; // Set mixPoints from Firestore or default to 0
        console.log(`Player id_old ${doc.id} has team: ${data.team}, mixPoints: ${data.mixPoints}`);
        console.log(`Player userId_new${data.userId} has team: ${data.team}, mixPoints: ${data.mixPoints}`);
      });

      // Log fetched team selections and mixPoints
      console.log("Fetched team selections:", selections);
      console.log("Fetched mixPoints for players:", playerMixPoints);

      // Update local state with team selections and mixPoints
      setTeamSelections(selections);
      setPlayerData((prev) => 
        prev.map(player => ({
          ...player,
          mixPoints: playerMixPoints[player.userId] || 0
        }))
      );

    } catch (error) {
      console.error("Error fetching game details:", error);
    }
  };

  fetchGameDetails();
}, [mainGameId, TheGameId]);  // Trigger fetch on screen load
  


  //Trigger listener on screen load
  useEffect(() => {
    const subscribeToTeamSelections = () => {
      const playersRef = collection(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId,
        "Players"
      );
  
      // Set up a real-time listener with onSnapshot
      const unsubscribe = onSnapshot(playersRef, (snapshot) => {
        let selections = {};
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          
          // Use consistent `userId` field, depending on how data is stored in Firestore
          const userId = data.userId || doc.id; // Fallback to `doc.id` if `data.userId` is undefined
          selections[userId] = data.team || null;
        });
  
        setTeamSelections(selections);
        console.log("Updated teamSelections:", selections);
      });
  
      return unsubscribe;
    };
  
    subscribeToTeamSelections();
  }, [mainGameId, TheGameId]); // Dependencies for useEffect
  

  useEffect(() => {
    const fetchTeamSelections = async () => {
      try {
        const playersRef = collection(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players"
        );
        const currentGameSnapshot = await getDocs(playersRef);

        let selections = {};
        currentGameSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.userId ] = data.team || null; // Set team from Firestore or null if not set
        });

        // Update local state with fetched team selections
        setTeamSelections(selections);
      } catch (error) {
        console.error("Error fetching team selections:", error);
      }
    };

    fetchTeamSelections();
  }, [mainGameId, TheGameId]); // Trigger fetch on screen load

 
  
  useEffect(() => {
    const fetchAndSortPlayersByPointsAndGDForDate = async () => {
      try {
        setLoading(true);
  
        // Step 1: Fetch players for the specific game (TheGameId represents the game on a specific date)
        const playersRef = collection(
          database,
          "MainGame",
          mainGame,
          "TheGame",
          TheGameId,
          "Players"
        );
  
        const playersSnapshot = await getDocs(playersRef); // Fetch data for players for the given game (date)
        let allPlayers = {};
  
        // Step 2: Process player data for the given date
        playersSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const playerKey = data.userId;

           // Log each player's userId as the data is fetched
        console.log(`Fetched player userId: ${playerKey}`);

  
          // Only include players who are marked as "Going"
          if (data.value === "Going") {
            if (!allPlayers[playerKey]) {
              allPlayers[playerKey] = {
                Name: data.Name,
                email: data.email || "",
                userId: playerKey,
                totalPointsBefore: data.totalPointsBefore || 0,
                totalGDbeforeTest: data.totalGDbeforeTest || 0,
                team: data.team || null,
              };
            }
  
            // Log player's name and their points and GD for debugging
            console.log(`Player: ${data.Name}, totalPointsBefore: ${data.totalPointsBefore || 0}, totalGDbeforeTest: ${data.totalGDbeforeTest || 0}`);
          }
        });
  
        // Step 3: Sort players by totalPointsBefore and totalGDbeforeTest for the given date
        let allPlayersArray = Object.values(allPlayers); // Convert the object to an array
  
        // Sort by totalPointsBefore, totalGDbeforeTest, and Name
        allPlayersArray = allPlayersArray.sort((a, b) => {
          if (a.totalPointsBefore === b.totalPointsBefore) {
            if (a.totalGDbeforeTest === b.totalGDbeforeTest) {
              return a.Name.localeCompare(b.Name); // Sort by Name if both totalPointsBefore and totalGDbeforeTest are equal
            }
            return b.totalGDbeforeTest - a.totalGDbeforeTest; // Sort by totalGDbeforeTest if totalPointsBefore are equal
          }
          return b.totalPointsBefore - a.totalPointsBefore; // Sort by totalPointsBefore
        });

  
        // Step 4: Store sorted players
        originalData.current = allPlayersArray;
  
        // Step 5: Update the player data for the current game
        setPlayerData(allPlayersArray); // Update state with sorted players
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };
  
    fetchAndSortPlayersByPointsAndGDForDate();
  }, [mainGame, TheGameId]);
  

  
  
  

  // Clear all "Home" and "Away" team selections
  const clearAllTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Reset team selections in local state
      setTeamSelections({});

      // Create a Firestore batch to clear team selections in Firestore
      const batch = writeBatch(database);

      playerData.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(playerRef, { team: null }, { merge: true });
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("All teams cleared successfully in batch");
    } catch (error) {
      console.error("Error clearing teams in Firestore:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Randomize and save teams
  const randomizeAndSaveTeams = async () => {
    try {
      setLoading(true);
  
      // Randomly assign teams
      const shuffledPlayers = [...playerData].sort(() => 0.5 - Math.random());
      const updatedTeamSelections = {};
      const halfTeamSize = Math.ceil(shuffledPlayers.length / 2);
  
      shuffledPlayers.forEach((player, index) => {
        updatedTeamSelections[player.userId] = index < halfTeamSize ? "Home" : "Away";
      });
  
      setTeamSelections(updatedTeamSelections);
  
      const batch = writeBatch(database);
  
      for (const player of shuffledPlayers) {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
  
        // Check if the document exists before updating
        const docSnapshot = await getDoc(playerRef);
        if (docSnapshot.exists()) {
          batch.update(playerRef, { team: updatedTeamSelections[player.userId] });
        } else {
          console.log(`Document for userId ${player.userId} does not exist, skipping update.`);
        }
      }
  
      await batch.commit();
      console.log("Teams randomized and saved successfully.");
    } catch (error) {
      console.error("Error randomizing and saving teams:", error);
    } finally {
      setLoading(false);
    }
  };
  

  // Toggle (alternating) teams Home/Away
  const assignAlternatingTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Alternate teams between Home and Away
      const updatedTeamSelections = {};
      playerData.forEach((player, index) => {
        updatedTeamSelections[player.userId] =
          index % 2 === 0 ? "Home" : "Away";
      });

      // Update local state with alternating teams
      setTeamSelections(updatedTeamSelections);

      // Create a Firestore batch
      const batch = writeBatch(database);

      // Update Firestore with new alternating team selections
      playerData.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(
          playerRef,
          { team: updatedTeamSelections[player.userId] },
          { merge: true }
        );
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("Teams assigned in alternating Home/Away pattern.");
    } catch (error) {
      console.error("Error assigning teams in Firestore:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Function to update player's team in Firestore and state WITHOUT re-sorting, using background syncing
 // Function to update player's team in Firestore and state
 const updatePlayerTeam = async (userId, team) => {
  if (!userId || !mainGameId || !TheGameId) {
    console.error("Missing parameters for Firestore doc path");
    return;
  }

  console.log(`Updating team for userId: ${userId}, new team: ${team}`);

  // Firestore reference to the player document
  const playerRef = doc(
    database,
    "MainGame",
    mainGameId,
    "TheGame",
    TheGameId,
    "Players",
    userId
  );

  try {
    // Update Firestore with the selected team
    await setDoc(playerRef, { team: team }, { merge: true });

    // Update the local state with the latest team selection
    setTeamSelections((prev) => ({
      ...prev,
      [userId]: team,
    }));

    console.log(`Player team successfully updated in Firestore for userId: ${userId}`);
  } catch (error) {
    console.error("Error updating player team in Firestore:", error);
  }
};





// Updated calculatePoints function to include mixPoints in the totalPoints calculation
const calculatePoints = (
  scoreHome,
  scoreAway,
  playerTeam,
  gameSettings = {},
  mixPoints = 0,
  fouls = 0,
  isAbsent = false, // You can keep this parameter if needed for other parts of the app
  playerName
) => {
  // Extract game settings with fallback to 0 if any value is missing
  const drawPointsConfig = Math.max(gameSettings.drawPoints || 0, 0);
  const winPointsConfig = Math.max(gameSettings.winPoints || 0, 0);
  const losePointsConfig = gameSettings.losePoints ?? 0; // Allow negative losePoints
  const attendancePointsConfig = Math.max(gameSettings.attendancePoints || 0, 0);
  const teamGoalPointsConfig = Math.max(gameSettings.TeenGoalPoints || 0, 0);

  console.log(`Calculating points for ${playerName} (${playerTeam || 'Unknown Team'}) | Home: ${scoreHome}, Away: ${scoreAway}`);

  let drawPoints = 0,
      winPoints = 0,
      losePoints = 0,
      attendancePoints = 0,
      teenGoalPoints = 0,
      result = "";

  // Ignore isAbsent in points calculation
  attendancePoints = (scoreHome > 0 || scoreAway > 0) ? attendancePointsConfig : 0;

  // Determine the game result for this player
  if (scoreHome === scoreAway) {
    drawPoints = drawPointsConfig;
    result = "draw"; // Game ended in a draw
  } else if (playerTeam === "Home" && scoreHome > scoreAway) {
    winPoints = winPointsConfig;
    result = "win"; // Home team wins
  } else if (playerTeam === "Away" && scoreAway > scoreHome) {
    winPoints = winPointsConfig;
    result = "win"; // Away team wins
  } else {
    losePoints = losePointsConfig;
    result = "lost"; // Player's team lost
  }

  // Award Teen Goal Points if the player's team scored 10 or more goals
  if ((playerTeam === "Home" && scoreHome >= 10) || (playerTeam === "Away" && scoreAway >= 10)) {
    teenGoalPoints = teamGoalPointsConfig;
  }

  // Total points calculation
  const totalPoints = winPoints + drawPoints + losePoints + attendancePoints + teenGoalPoints + mixPoints - fouls;

  console.log("Points Breakdown:", { winPoints, drawPoints, losePoints, attendancePoints, teenGoalPoints, mixPoints, fouls });
  console.log(`TotalPoints: ${totalPoints}, Result: ${result}`);

  return {
    totalPoints,
    result,
    winPoints,
    drawPoints,
    losePoints,
    attendancePoints,
    teenGoalPoints,
    mixPoints,
    fouls,
  };
};







// Update the code where calculatePoints is called to pass the player's name
const savePlayerDataToFirestore = async (mainGameId, TheGameId, playerData) => {
  const batch = writeBatch(database);
  const playersRef = collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players");

  for (const player of playerData) {
    const team = teamSelections[player.userId]; // Use the latest team selection

    if (!player || !player.userId || !teamSelections[player.userId]) {
      console.error("Player or team not selected. Please select a team for the player.");
      continue;
    }

    // Check for an existing document with a matching userId
    const querySnapshot = await getDocs(query(playersRef, where("userId", "==", player.userId)));

    if (!querySnapshot.empty) {
      // Update existing player document if it exists
      const existingPlayerDoc = querySnapshot.docs[0];
      const playerRef = existingPlayerDoc.ref;
      console.log(`Updating existing player data for userId ${player.userId}`);

      // Retrieve game settings with fallback values if any are missing
      const winPoints = gameSettings.winPoints || 0;
      const drawPoints = gameSettings.drawPoints || 0;
      const losePoints = gameSettings.losePoints || 0;
      const attendancePoints = gameSettings.attendancePoints || 0;
      const teenGoalPoints = gameSettings.TeenGoalPoints || 0;
      const absentPoints = gameSettings.absentPoints || 0;

      // Calculate the points for the player
      const { totalPoints } = calculatePoints(
        scoreHome,
        scoreAway,
        team,
        { winPoints, drawPoints, losePoints, attendancePoints, teenGoalPoints, absentPoints },
        player.mixPoints || 0,
        player.fouls || 0,
        team === "Absent",
        player.Name || "Unknown"
      );

      const playerDataToSave = {
        points: totalPoints,
        team: team,
        mixPoints: player.mixPoints || 0,
        fouls: player.fouls || 0,
        userId: player.userId,
      };

      // Add update to batch
      batch.set(playerRef, playerDataToSave, { merge: true });
    } else {
      console.log(`No existing document for userId ${player.userId}, skipping.`);
    }
  }

  // Update overall game scores
  const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  batch.set(
    gameRef,
    {
      scoreHome: scoreHome,
      scoreAway: scoreAway,
    },
    { merge: true }
  );

  try {
    await batch.commit();
    console.log("All player data saved successfully.");
  } catch (error) {
    console.error("Error saving player data in batch: ", error);
  }
};




const handleSaveScores = async () => {
  try {
    // Check if all players have been assigned a team
    const playersWithoutTeam = playerData.filter((player) => !teamSelections[player.userId]);

    if (playersWithoutTeam.length > 0) {
      const playerNames = playersWithoutTeam.map(p => p.Name).join(', ');

      if (Platform.OS === 'web') {
        // Use window.confirm for web
        const userConfirmed = window.confirm(
          `The following players do not have a team assigned: ${playerNames}. Please assign teams before saving.`
        );
        
        if (!userConfirmed) {
          return; // Stop if user cancels on web
        }
      } else {
        // Use Alert for mobile (iOS/Android)
        Alert.alert(
          "Incomplete Team Assignment",
          `The following players do not have a team assigned: ${playerNames}. Please assign teams before saving.`,
          [
            {
              text: "OK",
              onPress: () => console.log("User acknowledged the issue and will assign teams."),
            }
          ],
          { cancelable: false } // Prevent dismissal by tapping outside the alert
        );

        return; // Stop execution on mobile
      }
    }

    // If no issues, proceed with saving scores and data
    const userId = auth.currentUser.uid;
    const userName = await fetchUserName(userId);

    // Save player data (including team selections) to Firestore
    console.log("Saving player data to Firestore...");
    await savePlayerDataToFirestore(mainGameId, TheGameId, playerData);
    console.log("Player data saved successfully.");

    // Save game scores to Firestore
    console.log("Saving game scores to Firestore...");
    await saveGameScores(mainGameId, TheGameId, scoreHome, scoreAway, userId, userName);
    console.log("Game scores saved successfully.");

    // Call calculateAndStoreTotalPoints after saving game scores
    console.log("Calculating and storing total points...");
   // await calculateAndStoreTotalPoints();
    console.log("Total points calculated and stored successfully.");

  } catch (error) {
    console.error("Error saving scores:", error);
    alert("An error occurred while saving scores. Please try again.");
  }
};


 


// find name from uid. 
const fetchUserName = async (userId) => {
  try {
    const userRef = doc(database, "users", userId); // Reference to the user's document in Firestore
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const { name } = userSnapshot.data(); // Extract the 'name' field from the user document
      return name;
    } else {
      console.error("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user name:", error);
    return null;
  }
};

  // Function to handle saving scores
  // Save scores to "TheGame" document
// Save scores to "TheGame" document along with additional stats like GD, GF, GA, etc.
const saveGameScores = async (mainGameId, TheGameId, scoreHome, scoreAway, userId, userName) => {
  const batch = writeBatch(database);
  const playersRef = collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players");

  try {
    for (const player of playerData) {
      if (!teamSelections[player.userId]) {
        console.error(`Player ${player.Name} does not have a team assigned.`);
        continue;
      }

      const playerTeam = teamSelections[player.userId];
      const playerQuerySnapshot = await getDocs(query(playersRef, where("userId", "==", player.userId)));

      if (!playerQuerySnapshot.empty) {
        const playerRef = playerQuerySnapshot.docs[0].ref;
        console.log(`Updating existing player data for userId ${player.userId}`);

        const mixPoints = player.mixPoints || 0;

        // Retrieve game settings with fallback values
        const winPoints = gameSettings.winPoints || 0;
        const drawPoints = gameSettings.drawPoints || 0;
        const losePoints = gameSettings.losePoints || 0;
        const attendancePoints = gameSettings.attendancePoints || 0;
        const teenGoalPoints = gameSettings.TeenGoalPoints || 0;
        const absentPoints = gameSettings.absentPoints || 0;

        // Calculate points based on game settings
        const pointsResult = calculatePoints(
          scoreHome,
          scoreAway,
          playerTeam,
          gameSettings, // Pass the entire gameSettings object here
          mixPoints,
          player.fouls || 0,
          playerTeam === "Absent",
          player.Name || "Unknown"
        );

        if (!pointsResult) {
          console.error(`Failed to calculate points for player ${player.Name}`);
          continue;
        }

        // Only destructure `totalPoints` and `result` here, avoiding redeclaration of points variables
        const { totalPoints, result } = pointsResult;

        let gf = playerTeam === "Home" ? scoreHome : scoreAway;
        let ga = playerTeam === "Home" ? scoreAway : scoreHome;
        let gd = gf - ga;

        batch.set(
          playerRef,
          {
            scoreHome,
            scoreAway,
            winPoints,
            drawPoints,
            losePoints,
            attendancePoints,
            teenGoalPoints,
            totalPoints,
            absentPoints,
            result,
            fouls: player.fouls || 0,
            mixPoints,
            gf,
            ga,
            gd
          },
          { merge: true }
        );
      } else {
        console.log(`No existing document for userId ${player.userId}, skipping.`);
      }
    }

    const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
    batch.set(
      gameRef,
      {
        scoreHome: scoreHome,
        scoreAway: scoreAway,
        savedBy: {
          userId: userId,
          name: userName,
        },
      },
      { merge: true }
    );

    await batch.commit();
    console.log("Game scores and mixPoints saved successfully.");
  } catch (error) {
    console.error("Error saving game scores:", error);
    throw new Error("Failed to save game scores to Firestore.");
  }
};








  
const calculateAndStoreTotalPoints = async () => {
  try {
    const gamesRef = collection(database, "MainGame", mainGameId, "TheGame");
    const gamesSnapshot = await getDocs(gamesRef);

    const gameDates = gamesSnapshot.docs
      .map((gameDoc) => ({
        gameId: gameDoc.id,
        gameDate: new Date(gameDoc.data().date),
      }))
      .sort((a, b) => a.gameDate - b.gameDate);

    // Loop through all games in chronological order and update each player's total points and GD
    for (const { gameId: currentGameId } of gameDates) {
      const playersRef = collection(database, "MainGame", mainGameId, "TheGame", currentGameId, "Players");
      const playersSnapshot = await getDocs(playersRef);

      const playersData = playersSnapshot.docs.map((doc) => ({
        playerId: doc.userId ,
        ...doc.data(),
      }));

      // Calculate total points and GD for each player across all games
      for (const player of playersData) {
        let totalPoints = 0;
        let previousTotalPoints = 0;
        let totalGD = 0;
        let previousTotalGD = 0;

        // Loop through all games and accumulate points and GD for the player
        for (const { gameId } of gameDates) {
          const playerRef = doc(database, "MainGame", mainGameId, "TheGame", gameId, "Players", player.playerId);
          const playerSnapshot = await getDoc(playerRef);
          const playerData = playerSnapshot.data();

          if (playerData) {
            if (playerData.points != null) {
              totalPoints += playerData.points;
            }
            if (playerData.gd != null) {
              totalGD += playerData.gd;
            }

            // Store previous total points and GD in the database
            await setDoc(
              playerRef,
              {
                totalPointsBefore: previousTotalPoints,
                newTotalPoints: totalPoints,
                totalGDbefore: previousTotalGD,
                newTotalGD: totalGD,
              },
              { merge: true }
            );

            previousTotalPoints = totalPoints;
            previousTotalGD = totalGD;
          }
        }
      }
    }

    console.log("Total points and GD calculated and stored for all players.");
  } catch (error) {
    console.error("Error calculating and storing total points and GD:", error);
  }
};


  
  
  
  
  
  // Add this function to update mixPoints in Firebase, allowing negative values
  const updateMixPoints = async (userId, increment) => {
    const playersRef = collection(
      database,
      "MainGame",
      mainGameId,
      "TheGame",
      TheGameId,
      "Players"
    );
  
    try {
      // Query for the document with the specified userId field
      const querySnapshot = await getDocs(query(playersRef, where("userId", "==", userId)));
      
      if (!querySnapshot.empty) {
        // Get the first document where userId matches
        const playerDoc = querySnapshot.docs[0];
        const playerRef = playerDoc.ref;
        const currentMixPoints = playerDoc.data().mixPoints || 0;
  
        // Calculate the new mixPoints
        const updatedMixPoints = increment ? currentMixPoints + 1 : currentMixPoints - 1;
  
        // Update mixPoints in Firestore
        await updateDoc(playerRef, { mixPoints: updatedMixPoints });
        console.log(`MixPoints updated for userId: ${userId}. New mixPoints: ${updatedMixPoints}`);
  
        // Update local state for that specific player
        setPlayerData((prevData) =>
          prevData.map((player) =>
            player.userId === userId ? { ...player, mixPoints: updatedMixPoints } : player
          )
        );
      } else {
        console.error("Player document not found for userId:", userId);
      }
    } catch (error) {
      console.error("Error updating mixPoints in Firebase:", error);
    }
  };

  

  
useEffect(() => {
  console.log("Team Selections:", teamSelections);
}, [teamSelections]);



// Update the RenderItem function to include mixPoints display and increment/decrement buttons
function RenderItem({ item }) {
  const teamSelection = teamSelections[item.userId] || null;
  const mixPoints = item.mixPoints || 0;
  const totalPointsBefore = item.totalPointsBefore || 0;
  const totalGDbeforeTest = item.totalGDbeforeTest || 0;

  return (
    <View style={styles.playerRow}>
      {/* Rank column - aligned left with pink background */}
      <View style={styles.rankColumn}>
        <Text style={styles.rankText}>{totalPointsBefore}</Text>
      </View>

      <View style={styles.rankColumn}>
        <Text style={styles.rankText}>{totalGDbeforeTest}</Text>
      </View>

      {/* Player Name column */}
      <View style={{ flexDirection: "column", flexGrow: 1, marginLeft: 10 }}>
        <Text style={styles.playerName}>{item.Name}</Text>
        {/* <Text style={styles.playerEmail}>{item.email}</Text> */}
      </View>

      <View style={{ flexDirection: "row", alignItems: "center" }}>
      {/* Home button */}
      <Pressable
        onPress={() => handleTeamSelection(item.userId, "Home")}
        style={[
          styles.teamButton,
          styles.homeButton, // Apply the new homeButton style
          teamSelection === "Home" && styles.teamSelected,
        ]}
      >
        <Text style={styles.teamText}>Home</Text>
      </Pressable>

      {/* Away button */}
      <Pressable
        onPress={() => handleTeamSelection(item.userId, "Away")}
        style={[
          styles.teamButton,
          styles.awayButton, // Apply the new awayButton style
          teamSelection === "Away" && styles.teamSelected,
        ]}
      >
        <Text style={styles.teamText}>Away</Text>
      </Pressable>

      {/* Absent button */}
      <Pressable
        onPress={() => handleTeamSelection(item.userId, "Absent")}
        style={[
          styles.teamButton,
          styles.absentButton, // Apply the new absentButton style
          teamSelection === "Absent" && styles.teamSelected,
        ]}
      >
        <Text style={styles.teamText}>Absent</Text>
      </Pressable>

        <Pressable
          onPress={() => updateMixPoints(item.userId, false)}
          style={styles.mixPointsButton}
        >
          <Text>-</Text>
        </Pressable>

        <Text style={styles.mixPointsValue}>{mixPoints}</Text>

        <Pressable
          onPress={() => updateMixPoints(item.userId, true)}
          style={styles.mixPointsButton}
        >
          <Text>+</Text>
        </Pressable>
      </View>
    </View>
  );
}





 






  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* Show spinner if loading */}
      {loading && (
        <View style={styles.loadingOverlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}

      {/* Top buttons */}
      <View style={styles.topButtonsContainer}>
        <Pressable
          style={styles.topButton}
          onPress={clearAllTeams}
          disabled={loading}
        >
          <Text>Clear Teams</Text>
        </Pressable>

        <Pressable
          style={styles.topButton}
          onPress={assignAlternatingTeams}
          disabled={loading}
        >
          <Text>Toggle Teams</Text>
        </Pressable>

        <Pressable
          style={styles.topButton}
          onPress={randomizeAndSaveTeams}
          disabled={loading}
        >
          <Text>Random Teams</Text>               
        </Pressable>   
        <View>

        <View>
  {/* Pressable to open modal */}
  <Pressable
    style={styles.iconButton}
    onPress={() => setModalVisible(true)}
  >
    <MaterialIcons name="info" size={30} color="#2196F3" />
  </Pressable>

  {/* PointSystemModal component */}
  <PointSystemModal
  gameSettings={gameSettings}
  modalVisible={modalVisible}
  setModalVisible={setModalVisible}
  mainGameId={mainGameId}   // Pass mainGameId to the modal
  TheGameId={TheGameId}     // Pass TheGameId to the modal
/>

</View>





  {/* PointSystemModal component */}
  {/* <PointSystemModal gameSettings={gameSettings} /> */}
</View>
     
      </View>

      <View>          
  {/* <Text>
    {gameSettings && gameSettings.pointsEnabled !== undefined 
      ? `Points Enabled: ${gameSettings.pointsEnabled}` 
      : "Loading points settings..."}
  </Text> */}
</View>

      <View style={styles.header}>
        <Text>
          Name: {gameSettings?.name ?? "Loading..."} | Date:{" "}
          {moment(gameDate).format("DD MMMM YYYY")}
        </Text>
      </View>


  


 
{/* Save Scores Button Inside the Score Table */}
<View style={styles.scoreTable}>

<Pressable
  style={({ pressed }) => [
    styles.saveButton,
    pressed && styles.saveButtonPressed, // Apply the pressed style when button is pressed
  ]}
  onPress={() => {
    if (isAdmin) {
      // If admin, save the scores
      handleSaveScores();
    }
   //  Navigate to the GameDashboard for both admin and non-admin users
    navigation.navigate("GameDashboard", {
      gameDate,
      mainGameId,
      TheGameId,
    });
  }}
  android_ripple={{ color: "#fff", radius: 200 }} // Ripple effect on Android
>
  <Text style={styles.saveButtonText}>
    {isAdmin ? "Save Live Scores" : "Show Live Scores"}
  </Text>
</Pressable>




{/* 
<Pressable
  style={({ pressed }) => [
    styles.saveButton,
    pressed && styles.saveButtonPressed, // Apply the pressed style when button is pressed
  ]}
  onPress={handleSaveScores}
  android_ripple={{ color: "#fff", radius: 200 }} // Ripple effect on Android
>
  <Text style={styles.saveButtonText}>Save Live Scores!</Text>
</Pressable> */}


  {/* Colorful Leaderboard Table */}
  <View style={styles.scoreTableRow}>
  <View style={styles.teamSection}>
    <Text style={styles.homeLabel}>Home Team</Text>
    <TextInput
      style={[styles.scoreInput]}
      keyboardType="numeric"
      placeholder="0"
      returnKeyType="done"
      placeholderTextColor="#000"
      value={scoreHome.toString()} // Bind the state and ensure it displays as a string
      onChangeText={(value) => isAdmin && setScoreHome(parseInt(value) || 0)} // Only allow changes if user is admin
      editable={isAdmin} // Make it non-editable for non-admins
    />
  </View>

  <Text style={styles.vsLabel}>vs</Text>

  <View style={styles.teamSection}>
    <Text style={styles.awayLabel}>Away Team</Text>
    <TextInput
      style={[styles.scoreInput]}
      keyboardType="numeric"
      placeholder="0"
      returnKeyType="done"
      placeholderTextColor="#000"
      value={scoreAway.toString()} // Bind the state and ensure it displays as a string
      onChangeText={(value) => isAdmin && setScoreAway(parseInt(value) || 0)} // Only allow changes if user is admin
      editable={isAdmin} // Make it non-editable for non-admins
    />
  </View>
</View>


</View>


      {/* Sorting buttons */}
      <View style={styles.sortButtonsContainer}>
        <Pressable
          style={[
            styles.sortButton,
            sortBy === "Rank"
              ? styles.sortButtonActive
              : styles.sortButtonInactive,
          ]}
          onPress={() => setSortBy("Rank")}
        >
          <Text style={styles.sortButtonText}>Sort by Rank</Text>
        </Pressable>

        <Pressable
          style={[
            styles.sortButton,
            sortBy === "Name"
              ? styles.sortButtonActive
              : styles.sortButtonInactive,
          ]}
          onPress={() => setSortBy("Name")}
        >
          <Text style={styles.sortButtonText}>Sort by Name</Text>
        </Pressable>
      </View>

{/* Display the total counter for all players and teams */}
{/* Updated counterContainer with flex properties for alignment */}
<View style={styles.counterContainer}>
  <Text 
    style={[
      styles.counterText, 
      { 
        width: 80, 
        color: (homeCount + awayCount + absentCount === playerData.length && playerData.length > 0) ? 'green' : 'red' 
      }
    ]}
  >
    Total:  {homeCount + awayCount + absentCount} / {playerData.length}
  </Text>  
  {/* <Text style={styles.counterText}>Home: {homeCount}</Text>
  <Text style={styles.counterText}>Away: {awayCount}</Text> */}

  <Text 
  style={[
    styles.counterText, 
    { 
      color: (homeCount === awayCount && playerData.length > 0) ? 'green' : 'orange' 
    }
  ]}
>
  Home:  {homeCount} 
</Text>

{/* <Text style={styles.counterText}>Total Selected: {homeCount + awayCount + absentCount}</Text> */}

<Text 
  style={[
    styles.counterText, 
    { 
      color: (homeCount === awayCount && playerData.length > 0) ? 'green' : 'orange' 
    }
  ]}
>
  Away: {awayCount}
</Text>
<Text style={styles.counterText}>Absent: {absentCount}</Text>





</View>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
      <FlatList
  data={playerData}
  ListHeaderComponent={() => (
    <View style={styles.listHeader}>
      <Text style={styles.headerRank}>Point</Text> 
      <Text style={styles.headerRank}>GD</Text> 
      <Text style={styles.headerText}>Name</Text> 
      <Text style={[styles.headerText, { flex: 2 }]}>Team</Text> 
      <Text style={styles.headerText}>Points</Text>
    </View>
  )}
  renderItem={({ item }) => (
    <RenderItem item={item} updatePlayerTeam={updatePlayerTeam} />
  )}
  keyExtractor={(item) => item.userId}
  contentContainerStyle={styles.flatListContent}
/>


      </ScrollView>
    </SafeAreaView>
  );
};

export default Results;


const styles = StyleSheet.create({
  playerRow: {
  flexDirection: "row",
  alignItems: "center",
  paddingVertical: 5,
  paddingHorizontal: 10,
  borderBottomWidth: 1,
  borderBottomColor: "#ccc",
},
  rank: {
  fontSize: 16,
  fontWeight: "bold",

  marginRight: 10,
  width: 40, // Reduce width for Rank column
},
  playerName: {
    fontSize: 16,
    width: 80, // Reduce width for Rank column
    backgroundColor: "lightblue",

  },
  playerEmail: {
    fontSize: 12,
    color: "#666",
  },
  teamButton: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#ddd",    
    marginHorizontal: 5,
    width: 70, // Reduce width for Rank column

  },
  teamSelected: {
    backgroundColor: "#4C7BEE",
  },
  flatListContent: {
    paddingVertical: 10,
  },
  header: {
    padding: 10,
    backgroundColor: "#eee",
    alignItems: "center",
  },
  assignTeamsButton: {
    padding: 10,
    backgroundColor: "#4CAF50", // Green color for the button
    borderRadius: 8,
    margin: 10,
  },
  assignTeamsButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  clearTeamsButton: {
    padding: 10,
    backgroundColor: "#f44336", // Red color for the clear button
    borderRadius: 8,
    margin: 10,
  },
  clearTeamsButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  listHeader: {
  flexDirection: "row",
  //paddingVertical: 5,
  paddingHorizontal: 10,
  backgroundColor: "#f0f0f0",
  borderBottomWidth: 1,
  borderBottomColor: "#ccc",
  justifyContent: "space-between",  // Ensure proper spacing between items
  alignItems: "center",  // Vertically center the header text
  //backgroundColor: "#27496D", // Dark background color
},
headerText: {
  fontWeight: "bold",
  fontSize: 16,
  width: 80, // Adjust width for Rank and Points
  textAlign: "center", // Align text to the center of each column
  
},





  randomizeButton: {
    padding: 10,
    backgroundColor: "#FFA500", // Orange color for randomize button
    borderRadius: 8,
    margin: 10,
  },
  randomizeButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  topButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 5, // Reduce margin
  },
  topButton: {
    padding: 5, // Reduce padding
    backgroundColor: "#e8e8e8",
    borderRadius: 6, // Adjust border radius
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5, // Reduce horizontal margin
  },
  loadingOverlay: {
  position: "absolute", // Make the spinner overlay absolute
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
  zIndex: 10, // Ensure it's on top
  elevation: 10, // Android shadow (for elevation support)
},
  scoreboardContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 20,
    padding: 10,
    borderRadius: 15,
    backgroundColor: "#101820", // Dark background to match the image
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 8,
    elevation: 10, // Android shadow
  },
  teamContainer: {
    flex: 1,
    alignItems: "center",
    padding: 10,
    borderRadius: 10,
  },
  homeTeam: {
    backgroundColor: "#6a1b9a", // Purple-like color for home team
  },
  awayTeam: {
    backgroundColor: "#0288d1", // Blue-like color for away team
  },
  teamLogo: {
    width: 80,
    height: 80,
    marginBottom: 10,
  },
  teamName: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  scoreContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1e1e2f",
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 10,
  },
  scoreText: {
    color: "#fff",
    fontSize: 50,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
  timerText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
 
  scoreTable: {
  borderWidth: 1,
  borderColor: "#ccc",
  borderRadius: 10,
  margin: 20,
  backgroundColor: "#27496D", // Dark background color

  // Existing shadow settings
  shadowColor: "#000",
  shadowOpacity: 0.3,
  shadowOffset: { width: 0, height: 2 }, 
  shadowRadius: 8,
  elevation: 10, // Android shadow

  // Add glow effect at the bottom
  shadowColor: "#4C7BEE", // Same color as your button for consistency
  shadowOffset: { width: 0, height: 6 }, // Strong shadow below the table
  shadowOpacity: 0.7, // Higher opacity for more visible glow
  shadowRadius: 5, // Larger radius for soft spread glow
  elevation: 15, // Ensure Android gets the effect too
},

  scoreTableRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  teamSection: {
    flexDirection: "column",
    alignItems: "center",
    marginHorizontal: 20,
  },
  homeLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#4CAF50",
    textAlign: "center",
    marginBottom: 5,
  },
  awayLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FF5722",
    textAlign: "center",
    marginBottom: 5,
  },
  vsLabel: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
    marginHorizontal: 20,
  },
  scoreInput: {
    width: 100,
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 8,
    textAlign: "center",
    fontSize: 24,
    color: "#000",
    backgroundColor: "#f0f0f0",
  },

  saveButtonPressed: {
  backgroundColor: "#3B68CC", // Darker shade of green when pressed
  transform: [{ scale: 0.98 }], // Slightly shrink the button to give a pressed effect
},

saveButton: {
  marginTop: -1,
  paddingVertical: 5, // Extreme padding for a bigger button
  paddingHorizontal: 35, 
  backgroundColor: "#4C7BEE", // Vibrant color for the button itself
  borderRadius: 15, // More rounded corners for a bold look
  alignItems: "center",
  justifyContent: "center",
  
  // Extreme shadow settings
  shadowColor: "#000",
  shadowOffset: { width: 0, height: 12 }, // Very large vertical shadow
  shadowOpacity: 0.9, // Almost opaque shadow
  shadowRadius: 15, // Wide, soft shadow
  elevation: 15, // High elevation for a deeper shadow on Android

  // Optional border and glow for a futuristic effect
  borderColor: "#fff",
  borderWidth: 2,
  shadowColor: "#00FFFF", // Neon-like glow effect
  shadowOffset: { width: 0, height: 0 }, // Shadow on all sides
  shadowOpacity: 0.7, // Visible shadow-glow mix
  shadowRadius: 25,
},
saveButtonText: {
  color: "#fff",
  fontSize: 15, // Larger font size for impact
  fontWeight: "bold",
  textTransform: "uppercase", // Keep uppercase for emphasis
  letterSpacing: 2, // Spacing between letters for a strong effect
},


  homeInput: {
    backgroundColor: "#E8F5E9", // Light green for Home score input
  },
  awayInput: {
    backgroundColor: "#FFEBEE", // Light red for Away score input
  },
  sortButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  sortButton: {
    padding: 10,
    backgroundColor: "#e8e8e8",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5,
  },

  sortButtonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  sortButtonActive: {
    backgroundColor: "#4C7BEE",
  },
  sortButtonInactive: {
    backgroundColor: "#ddd",
  },

  counterContainer: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "space-between", // Aligns items horizontally with equal spacing
  },
  counterText: {
    fontSize: 12,
    color: "#555", // Softer color for better readability
  },

  foulButton: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#f0a500", // Example color for foul buttons
    marginHorizontal: 5,
  },
  foulValue: {
    fontSize: 16,
    fontWeight: "bold",
    marginHorizontal: 10, // Space between the number and the buttons
  },

  // Add this style for the mixPoints buttons and value
   mixPointsButton: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#f0a500", // Example color for mixPoints buttons
    marginHorizontal: 5,
  },
  mixPointsValue: {
    fontSize: 16,
    fontWeight: "bold",
    marginHorizontal: 10, // Space between the number and the buttons
  },
  totalPointsBeforeValue: {
    fontSize: 16,
    fontWeight: "bold",
    marginHorizontal: 10, // Space between the number and other buttons
  },

  rankColumn: {
    width: 35,               // Adjust width for the rank column
    backgroundColor: "pink",  // Background color set to pink
    justifyContent: "center", // Align text vertically centered
    alignItems: "center",     // Align text horizontally centered
    //padding: 5,               // Optional padding for better visibility
   // marginRight: 10,          // Add space between rank and the player name
  },

  rankText: {
    fontSize: 16,
    //fontWeight: "bold",
  },

  headerRank: {
  fontWeight: "bold",
  fontSize: 12,
  width: 35, // Adjust width for Rank and Points
  textAlign: "center", // Align text to the center of each column
  backgroundColor: "pink" 
},




});



 