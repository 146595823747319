import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, initializeAuth, getReactNativePersistence, indexedDBLocalPersistence } from 'firebase/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { Platform } from 'react-native';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDqsqtofl3Co8H5C3a04k-f-6i7Fz-nZn0",
  authDomain: "quizapp-784c4.firebaseapp.com",
  projectId: "quizapp-784c4",
  storageBucket: "quizapp-784c4.appspot.com",
  messagingSenderId: "862842357540",
  appId: "1:862842357540:web:9d637656583a2661dd15b1",
  measurementId: "G-02MVVN1ZKJ"
};




// Initialize Firebase app if not already initialized
const app = initializeApp(firebaseConfig);
console.log("Firebase app initialized:", app.name);

// Conditionally initialize Firebase Auth based on platform
let auth;
if (Platform.OS === 'ios' || Platform.OS === 'android') {
  auth = initializeAuth(app, {
    persistence: getReactNativePersistence(AsyncStorage)
  });
  console.log("Firebase Auth initialized for mobile platforms.");
} else {
  auth = getAuth(app);
  console.log("Firebase Auth initialized for web.");



}


// Initialize Firestore
const database = getFirestore(app);
console.log("Firestore initialized:", database);

// Initialize Google Analytics
let analytics;
if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
  analytics = getAnalytics(app);
  setAnalyticsCollectionEnabled(analytics, true);  // Enable automatic data collection
  console.log("Google Analytics initialized:", analytics);

  // Log a test event to verify that Analytics is working
  logEvent(analytics, 'test_event', { description: 'Test event from Expo web' });
  console.log("Google Analytics event 'test_event' logged.");

  // Check network requests in the browser and Real-Time analytics in GA to verify this
} else {
  console.log("Google Analytics not supported on this platform.");
}

export { auth, database, analytics };