// Avatar.js
import { useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { database } from "../componenets/firebase";

const useAvatarUri = () => {
  const [avatarCache, setAvatarCache] = useState({});

  const fetchAvatarUri = async (userId) => {
    if (!userId) {
      return null;
    }

    // Use the cached avatar URI if it exists
    if (avatarCache[userId]) {
      return avatarCache[userId];
    }

    // Only fetch the avatarUri if the userId is not empty
    const userDocRef = doc(database, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();

      // Check if the avatarUri field exists in userData
      if ('avatarUri' in userData) {
        const avatarUri = userData.avatarUri;

        // Update the avatarCache with the fetched avatar URI
        setAvatarCache((prevCache) => ({ ...prevCache, [userId]: avatarUri }));

        return avatarUri;
      } else {
        return null;
      }
    }

    return null; // Default avatar URI is null
  };

  return fetchAvatarUri;
};

export default useAvatarUri;
