import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  ImageBackground,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";

import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { auth } from "../../componenets/firebase";
import { getAndStorePushToken } from "./getToken"; // Import the token function

const SignInScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [inputFocus, setInputFocus] = useState({
    email: false,
    password: false,
  });

  const handleFocus = (field) => {
    setInputFocus((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setInputFocus((prev) => ({ ...prev, [field]: false }));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigation.navigate("Home");
      }
    });

    return unsubscribe;
  }, [navigation]);

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/user-not-found":
        return "No user found with this email. Please check your email address or sign up.";
      case "auth/wrong-password":
        return 'Incorrect password. Please try again or use the "Forgot password" option.';
      case "auth/too-many-requests":
        return "Too many attempts. Please try again later.";
      case "auth/email-already-in-use":
        return "Email is already in use by another account. Please use a different email.";
      default:
        return "An unexpected error occurred. Please try again later.";
    }
  };

  const login = async () => {
    if (!email || !password) {
      setValidationMessage("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        lastLoginAt: user.metadata.lastLoginAt,
      });

      await AsyncStorage.setItem("isLoggedIn", "true");

      // Store the user's Expo push token
      await getAndStorePushToken();

      navigation.navigate("Home"); // Navigate to home screen after successful login and token storage
    } catch (error) {
      console.error("Login failed:", error);
      setValidationMessage(getErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ImageBackground
      source={require("../../assets/background.jpg")}
      style={styles.backgroundImage}
    >
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform.select({
            ios: "padding",
            android: "height",
            web: "height",
          })}
          style={styles.keyboardAvoidingView}
        >
          <ScrollView contentContainerStyle={styles.innerContainer}>
            <Text style={styles.title}>Login to TeamPlay</Text>
            <View style={styles.inputContainer}>
              <MaterialIcons name="email" size={24} color="white" />
              <TextInput
                style={[
                  styles.inputStyle,
                  inputFocus.email && styles.inputFocused,
                ]}
                placeholder="Email"
                placeholderTextColor="white"
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
                autoCapitalize="none"
                onFocus={() => handleFocus("email")}
                onBlur={() => handleBlur("email")}
              />
            </View>
            <View style={styles.inputContainer}>
              <MaterialIcons name="lock" size={24} color="white" />
              <TextInput
                style={[
                  styles.inputStyle,
                  inputFocus.password && styles.inputFocused,
                ]}
                placeholder="Password"
                placeholderTextColor="white"
                secureTextEntry={true}
                value={password}
                onChangeText={setPassword}
                onFocus={() => handleFocus("password")}
                onBlur={() => handleBlur("password")}
              />
            </View>

            {validationMessage !== "" && (
              <Text style={styles.error}>{validationMessage}</Text>
            )}

            {loading ? (
              <ActivityIndicator size="large" color="#0000ff" />
            ) : (
              <Button
                title="Sign in"
                buttonStyle={styles.button}
                onPress={login}
              />
            )}

            <TouchableOpacity onPress={() => navigation.navigate("Sign Up")}>
              <Text style={styles.signUpText}>
                Don't have an account yet? Sign up here
              </Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => navigation.navigate("ForgotPassword")}>
              <Text style={styles.forgotPasswordText}>Forgot Password?</Text>
            </TouchableOpacity>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  keyboardAvoidingView: {
    flex: 1,
    width: "100%",
  },
  innerContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    color: "white",
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    maxWidth: 400,
    marginVertical: 12,
    borderBottomWidth: 1,
    borderColor: "gray",
    paddingVertical: 10,
    alignSelf: 'center',
  },
  inputStyle: {
    marginLeft: Platform.select({ ios: 0, android: 10, web: 10 }),
    flex: 1,
    fontSize: 16,
    color: "white",
  },
  error: {
    color: "red",
    marginTop: 10,
  },
  signUpText: {
    marginTop: 20,
    color: "#000000",
    fontSize: 16,
    backgroundColor: "#D3D3D3",
  },
  inputFocused: {
    borderColor: "#E6E6FA",
    borderWidth: 2,
  },
  forgotPasswordText: {
    color: "#000000",
    marginTop: 15,
    backgroundColor: "#D3D3D3",
    fontSize: 16,
  },
  button: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
});

export default SignInScreen;
