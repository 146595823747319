import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  ScrollView,
} from "react-native";
import { database } from "../componenets/firebase";
import {
  collectionGroup,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import moment from "moment";
import { Avatar } from "react-native-elements"; // Import the Avatar component
import useAvatarUri from "./Avatar"; // Import the hook for fetching avatar URIs

const LeagueTable = ({ navigation, route }) => {
  const [playerData, setPlayerData] = useState([]);
  const [mainGameId, setMainGame] = useState(route.params.mainGameId);
  const [pointsEnabled, setPointsEnabled] = useState(false);

  useEffect(() => {
    const fetchPointsEnabled = async () => {
      const gameRef = doc(database, "MainGame", mainGameId);
      const gameDoc = await getDoc(gameRef);

      if (gameDoc.exists()) {
        const gameData = gameDoc.data();
        setPointsEnabled(gameData.pointsEnabled || false);
      } else {
        console.log("No such document!");
      }
    };

    fetchPointsEnabled();
  }, [mainGameId]);

  const fetchAvatarUri = useAvatarUri(); // Initialize the hook

  const fetchAndProcessData = useCallback(() => {
    const playersQuery = query(
      collectionGroup(database, "Players"),
      where("mainGameId", "==", mainGameId)
    );

    const unsubscribe = onSnapshot(playersQuery, async (querySnapshot) => {
      let players = {};

      const playersWithAvatars = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const avatarUri = await fetchAvatarUri(data.userId); // Fetch avatar URI
          const playerData = { ...data, avatarUri }; // Add avatar URI to the player's data

          const gameDateFormatted = moment(playerData.gamedate).format("YYYY-MM-DD");
          const gameKey = `${gameDateFormatted}-${playerData.gameIndex}`;
          const playerKey = playerData.userId;

          if (!players[playerKey]) {
            players[playerKey] = {
              Name: playerData.Name,
              email: playerData.email || '',
              userId: playerData.userId || '',
              games: {},
              totalPoints: 0,
              totalGF: 0,
              totalGA: 0,
              totalGD: 0,
              avatarUri: playerData.avatarUri, // Add avatar URI to player's data
            };
          }

          if (playerData.totalPoints != null) {
            players[playerKey].games[gameKey] = playerData.totalPoints;
            players[playerKey].totalPoints += playerData.totalPoints;
            if (playerData.team === "Home") {
              players[playerKey].totalGF += playerData.scoreHome || 0;
              players[playerKey].totalGA += playerData.scoreAway || 0;
            } else {
              players[playerKey].totalGF += playerData.scoreAway || 0;
              players[playerKey].totalGA += playerData.scoreHome || 0;
            }
            players[playerKey].totalGD =
              players[playerKey].totalGF - players[playerKey].totalGA;
          }

          return playerData;
        })
      );

      const playersArray = Object.values(players).sort((a, b) => {
        if (b.totalPoints !== a.totalPoints) return b.totalPoints - a.totalPoints;
        if (b.totalGD !== a.totalGD) return b.totalGD - a.totalGD;
        if (b.totalGF !== a.totalGF) return b.totalGF - a.totalGF;
        return a.Name.localeCompare(b.Name);
      });

      // Assign places with tie handling
      let placeNumber = 1;
      for (let i = 0; i < playersArray.length; i++) {
        if (
          i > 0 &&
          playersArray[i].totalPoints === playersArray[i - 1].totalPoints &&
          playersArray[i].totalGD === playersArray[i - 1].totalGD &&
          playersArray[i].totalGF === playersArray[i - 1].totalGF
        ) {
          playersArray[i].place = "-";  // Assign "-" for tied places
        } else {
          playersArray[i].place = placeNumber;
        }
        placeNumber++;
      }

      setPlayerData(playersArray);
    });

    return () => unsubscribe();
  }, [mainGameId]);

  useEffect(() => {
    fetchAndProcessData();
  }, [mainGameId, fetchAndProcessData]);

  const renderItem = ({ index, item }) => (
    <View
      style={[
        styles.row,
        index % 2 === 1 ? styles.oddRow : null,
      ]}
    >
      <Text style={styles.place}>{item.place}</Text>
      <View style={styles.name}>
        <Avatar
          rounded
          size="small"
          source={item.avatarUri ? { uri: item.avatarUri } : null}
          title={!item.avatarUri ? item.Name.slice(0, 2).toUpperCase() : null}
          overlayContainerStyle={{ backgroundColor: "#bcbec1" }}
        />
        <Text style={styles.playerName}>{item.Name}</Text>
      </View>
      {pointsEnabled && (
        <Text style={styles.total}>{String(item.totalPoints || "-")}</Text>
      )}
      <Text style={styles.scoreWin}>{String(item.totalGF)}</Text>
      <Text style={styles.scoreLose}>{String(item.totalGA)}</Text>
      <Text style={styles.scoreDiff}>{String(item.totalGD)}</Text>
    </View>
  );
  

  const ListHeaderComponent = () => (
    <View style={styles.header}>
      <Text style={styles.headerPlace}>#</Text>
      <View style={styles.nameHeader}>
        <Text style={styles.headerText}>Name</Text>
      </View>
      {pointsEnabled && <Text style={styles.headerCell}>Points</Text>}
      <Text style={styles.headerCell}>GF</Text>
      <Text style={styles.headerCell}>GA</Text>
      <Text style={styles.headerCell}>GD</Text>
    </View>
  );

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "white", position: "relative" }}>
      <ScrollView horizontal>
        <FlatList
          data={playerData}
          keyExtractor={(item) => item.userId} // Use userId as the unique key
          renderItem={renderItem}
          ListHeaderComponent={ListHeaderComponent}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default LeagueTable;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 0,
    marginLeft: -8,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#CED0CE",
    backgroundColor: "#f2f2f2",
  },
  headerPlace: {
    width: 50,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
  },
  headerCell: {
    width: 80,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
  },
  nameHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 140,
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    flex: 1,
  },
  place: {
    width: 50,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 0,
    marginLeft: -8,
    borderBottomWidth: 1,
    borderBottomColor: "#CED0CE",
  },
  name: {
    flexDirection: "row",
    alignItems: "center",
    width: 140,
    fontSize: 16,
    paddingLeft: 10,
    paddingVertical: 5,
  },
  total: {
    width: 80,
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 5,
  },
  scoreWin: {
    width: 80,
    textAlign: "center",
    paddingLeft: 5,
    paddingVertical: 5,
  },
  scoreLose: {
    width: 80,
    textAlign: "center",
    paddingLeft: 5,
    paddingVertical: 5,
  },
  scoreDiff: {
    width: 80,
    textAlign: "center",
    paddingLeft: 5,
    paddingVertical: 5,
  },
  oddRow: {
    backgroundColor: "#f2f2f2",
  },
  playerName: {
    paddingVertical: 5,
    width: 130,
    marginLeft: 10, // Add this line to create space between the avatar and the name

  },
});
