// notificationUtils.js

import { Alert } from 'react-native';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getDoc, doc, collection, getDocs, getFirestore } from 'firebase/firestore';
import * as Notifications from 'expo-notifications';

// Set up notification handling
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

// Function to request web notification permission and send notification
export const sendWebNotification = (title, body) => {
  if (!('Notification' in window)) {
    Alert.alert('Web Notification', 'This browser does not support notifications.');
    return;
  }

  if (Notification.permission === 'granted') {
    new Notification(title, { body });
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        new Notification(title, { body });
      }
    });
  }
};

// Function to send a notification to a player using Firebase Cloud Function
export const sendNotificationToPlayer = async (expoPushToken, recipientName, senderName) => {
  if (!expoPushToken || expoPushToken === 'No Token') {
    console.log(`Invalid or missing token for ${recipientName}. Notification not sent.`);
    Alert.alert('Notification Failed', `${recipientName} does not have a valid Expo Push Token.`);
    return;
  }

  try {
    const functions = getFunctions();
    const sendNotification = httpsCallable(functions, 'sendNotification');

    console.log("Sending notification to:", recipientName, "from:", senderName);

    await sendNotification({
      expoPushToken,
      title: 'Team Play',
      body: `Hello ${recipientName}! Your friend ${senderName} wants to remind you about an upcoming game.`,
    });

    Alert.alert('Notification Sent', `Notification sent to ${recipientName} from ${senderName}`);
  } catch (error) {
    if (error.message.includes("Invalid Token")) {
      console.error(`Invalid token for ${recipientName}.`);
      Alert.alert('Notification Error', `Invalid token for ${recipientName}.`);
    } else {
      console.error(`Error sending notification to ${recipientName} from ${senderName}:`, error);
      Alert.alert('Notification Error', `Failed to send notification to ${recipientName}.`);
    }
  }
};

// Utility function to fetch the current user's name
export const fetchCurrentUserName = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const database = getFirestore();

  if (currentUser) {
    const userDocRef = doc(database, 'users', currentUser.uid);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.name || 'Unknown';
    } else {
      console.log("User document not found.");
      return 'Unknown';
    }
  } else {
    console.log("No user is logged in.");
    return 'Unknown';
  }
};

// Utility function to fetch game and player data
export const fetchGameDataAndPlayers = async (mainGameId) => {
  const database = getFirestore();
  const mainGameDocRef = doc(database, 'MainGame', mainGameId);
  const mainGameSnapshot = await getDoc(mainGameDocRef);
  let combinedData = [];
  let gameDatesWithTime = [];

  if (mainGameSnapshot.exists()) {
    const mainGameData = mainGameSnapshot.data();
    const players = mainGameData.memberOfGame || [];
    const gamesCollectionRef = collection(database, 'MainGame', mainGameId, 'TheGame');
    const gamesSnapshot = await getDocs(gamesCollectionRef);

    await Promise.all(gamesSnapshot.docs.map(async (gameDoc) => {
      const gameData = gameDoc.data();
      const gameDate = gameData.date;
      const gameTime = gameData.time;

      if (!gameDatesWithTime.some(game => game.date === gameDate && game.time === gameTime)) {
        gameDatesWithTime.push({ date: gameDate, time: gameTime });
      }

      await Promise.all(players.map(async (player) => {
        let expoPushToken = 'No Token';

        if (player.uid) {
          const userDocRef = doc(database, 'users', player.uid);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            expoPushToken = userData.expoPushToken || 'No Token';
          }
        }

        const isPlayerAlreadyInList = combinedData.some(item => item.uid === player.uid);

        if (!isPlayerAlreadyInList) {
          combinedData.push({
            uid: player.uid,
            name: player.name,
            email: player.email || 'No Email',
            expoPushToken: expoPushToken,
          });
        }
      }));
    }));
  } else {
    console.log("Main game document not found.");
  }

  return { gameDatesWithTime, combinedData };
};
