// bug.js

import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  Alert,
  Platform,
  ScrollView,
  View,
  Text,
  TextInput,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { database } from "../componenets/firebase"; // Adjust the path according to your project structure
import {
  collection,
  query,
  getDocs,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import { Picker } from "@react-native-picker/picker";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { MaterialIcons } from "@expo/vector-icons";


const Stack = createNativeStackNavigator();



//const BugReportApp = () => {
const BugReportScreen = ({ navigation }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bugReports, setBugReports] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  // Add to your component's state
  const [adminComments, setAdminComments] = useState({});

  const [isAdmin, setIsAdmin] = useState(false); // State to track if the user is an admin
  const auth = getAuth();

  // Fetch bug reports from Firestore on component mount
  useEffect(() => {
    // Fetch bug reports
    const q = query(collection(database, "Bug"));
    const unsubscribeReports = onSnapshot(q, (querySnapshot) => {
      const bugs = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const createdAt = data.createdAt?.toDate
          ? data.createdAt.toDate()
          : new Date();
        const formattedDate = moment(createdAt).format("LLL"); // Format the date with moment.js

        bugs.push({ id: doc.id, ...data, createdAt, formattedDate }); // Include formattedDate here
      });
      bugs.sort((a, b) => b.createdAt - a.createdAt);
      setBugReports(bugs);
    });

    // Handle authentication state changes
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(database, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
          setIsAdmin(user.email === "dondanni@hotmail.com");
        } else {
        //  console.log("No such user document!");
        }
      } else {
        setUserDetails({});
        setIsAdmin(false);
      }
    });

    // Cleanup function
    return () => {
      unsubscribeReports();
      unsubscribeAuth();
    };
  }, []); // Dependencies array

  const submitReport = async () => {
    const newReport = {
      title,
      description,
      status: "Reported",
      adminComment: "",
      createdAt: serverTimestamp(), // Use Firestore server timestamp
      userEmail: userDetails.email, // Assuming you have userDetails available
      userName: userDetails.name,
    };

    try {
      await setDoc(doc(database, "Bug", newReport.title), newReport);
      //  alert('Bug report submitted successfully.');
      // Reset form fields here if needed
    } catch (error) {
      console.error("Error submitting bug report: ", error);
      alert("Please fill out bug report fields.");
    }
  };

  const updateAdminComment = async (bugId, comment) => {
    const bugRef = doc(database, "Bug", bugId);
    try {
      await updateDoc(bugRef, { adminComment: comment });
    //  console.log("Admin comment updated successfully.");

      // Clear the temporary state for this comment
      const updatedComments = { ...adminComments };
      delete updatedComments[bugId];
      setAdminComments(updatedComments);
    } catch (error) {
    //  console.error("Error updating admin comment: ", error);
    }
  };

  const updateStatus = async (bugId, newStatus) => {
    const bugRef = doc(database, "Bug", bugId);
    try {
      await updateDoc(bugRef, {
        status: newStatus,
      });
     // console.log("Bug status updated to:", newStatus);
    } catch (error) {
    //  console.error("Failed to update status:", error);
    }
  };

  const deleteBug = (bugId) => {
    // Platform-specific confirmation
    if (Platform.OS === "web") {
      // Web platform: Use window.confirm
      if (
        window.confirm(
          "Are you sure you want to delete this bug report? This action cannot be undone."
        )
      ) {
        performDeletion(bugId);
      }
    } else {
      // iOS and Android platforms: Use Alert.alert
      Alert.alert(
        "Confirm Deletion",
        "Are you sure you want to delete this bug report? This action cannot be undone.",
        [
          {
            text: "Cancel",
            onPress: () => console.log("Deletion cancelled"),
            style: "cancel",
          },
          {
            text: "Delete",
            onPress: () => performDeletion(bugId),
            style: "destructive",
          },
        ],
        { cancelable: false }
      );
    }
  };

  const performDeletion = async (bugId) => {
    const bugRef = doc(database, "Bug", bugId);
    try {
      await deleteDoc(bugRef);
     // console.log("Bug report deleted successfully.");
      // Here you might want to update the state or UI accordingly
    } catch (error) {
     // console.error("Error deleting bug report:", error);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={styles.scrollViewStyle}>
        <View style={styles.container}>
          {/* Correctly display text within <Text> components */}
          {/* <Text style={styles.userInfo}>Email: {userDetails.email || 'Not logged in'}</Text>
      <Text style={styles.userInfo}>Name: {userDetails.name || 'N/A'}</Text> */}
          {isAdmin && (
            <Text style={styles.adminInfo}>Admin Options Available</Text>
          )}

          <Text style={styles.header}>Report a Bug</Text>
          <TextInput
            style={styles.input}
            placeholder="Title"
            value={title}
            onChangeText={setTitle}
          />
          <TextInput
            style={[styles.input, styles.textArea]}
            placeholder="Description"
            multiline
            rows={4}
            value={description}
            onChangeText={setDescription}
          />
          <Pressable style={styles.submitButton} onPress={submitReport}>
            <Text style={styles.submitButtonText}>Submit Report</Text>
          </Pressable>

          <Text style={styles.subHeader}>Bug Reports</Text>

          <FlatList
            data={bugReports}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <View style={styles.reportItem}>
                <Text style={styles.reportTitle}>{item.title}</Text>
                <Text>{item.description}</Text>
                <Text style={styles.reportStatus}>Status: {item.status}</Text>
                <Text style={styles.reportUser}>
                  Reported by: {item.userName}{" "}
                  {isAdmin && `(${item.userEmail})`}on {item.formattedDate}{" "}
                </Text>
                {/* <Text style={styles.reportDate}>Reported on: {item.formattedDate}</Text> */}
                {item.adminComment?.trim() ? (
                  <Text>Admin Comment: {item.adminComment}</Text>
                ) : null}

                {isAdmin && (
                  <>
                    <TextInput
                      style={styles.input}
                      placeholder="Add/Edit Admin Comment"
                      value={adminComments[item.id] || item.adminComment || ""}
                      onChangeText={(text) => {
                        setAdminComments({ ...adminComments, [item.id]: text });
                      }}
                    />
                    <Pressable
                      style={[styles.adminButton, styles.saveCommentButton]}
                      onPress={() =>
                        updateAdminComment(
                          item.id,
                          adminComments[item.id] || ""
                        )
                      }
                    >
                      <Text style={styles.adminButtonText}>
                        Save admin Comment
                      </Text>
                    </Pressable>
                    {/* New Delete Bug Button */}
                    <Pressable
                      style={[styles.adminButton, styles.deleteButton]}
                      onPress={() => deleteBug(item.id)}
                    >
                      <Text style={styles.adminButtonText}>Delete Bug</Text>
                    </Pressable>
                    <Picker
                      selectedValue={item.status}
                      style={styles.pickerStyle}
                      onValueChange={(itemValue, itemIndex) =>
                        updateStatus(item.id, itemValue)
                      }
                    >
                      <Picker.Item label="Reported" value="Reported" />
                      <Picker.Item label="Processing" value="Processing" />
                      <Picker.Item label="Fixed" value="Fixed" />
                      <Picker.Item label="Won't Fix" value="Won't Fix" />
                    </Picker>
                  </>
                )}
              </View>
            )}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};


const BugReportApp = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="BugReportScreen"
        component={BugReportScreen}
        options={({ navigation }) => ({
          headerTitle: () => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => navigation.goBack()}
              style={{ marginRight: 10 }}
            >
              {/* <Ionicons name="arrow-back" size={24} color="white" /> */}
              <MaterialIcons name="arrow-back" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>
                Go back
              </Text>
            </TouchableOpacity>
          </View>
        ),
          headerStyle: {
            backgroundColor: "#4361ee",
          },
          headerTintColor: "white",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        })}
      />
    </Stack.Navigator>
  );
};

export default BugReportApp;


const styles = StyleSheet.create({
  scrollViewStyle: {
    flex: 1, // Ensure ScrollView takes up the full screen
  },
  container: {
    padding: 20,
    paddingBottom: 50, // Add some padding at the bottom for better spacing
  },
  header: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 20,
  },
  subHeader: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
  },
  input: {
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "#cccccc",
    padding: 10,
  },
  textArea: {
    height: 100,
  },
  reportItem: {
    marginBottom: 15,
    padding: 10,
    borderWidth: 1,
    borderColor: "#cccccc",
  },
  reportTitle: {
    fontWeight: "bold",
  },
  reportStatus: {
    marginTop: 5,
    fontStyle: "italic",
  },
  reportDate: {
    fontSize: 14,
    marginTop: 5,
  },

  submitButton: {
    backgroundColor: "#007bff", // A blue color for the button background
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },

  submitButtonAdmin: {
    backgroundColor: "#FD7E14", // A blue color for the button background
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },

  submitButtonText: {
    fontSize: 16,
    color: "#ffffff", // White color for the text
    fontWeight: "bold",
  },

  statusContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 10,
  },
  statusButton: {
    padding: 5,
    backgroundColor: "#dddddd",
    borderRadius: 5,
  },
  statusText: {
    fontWeight: "bold",
  },

  pickerStyle: {
    height: 50,
    width: "100%",
    marginTop: 10,
  },

  pickerContainer: {
    borderWidth: 1,
    borderColor: "#cccccc",
    borderRadius: 8,
    marginBottom: 20,
    overflow: "hidden", // Ensures the Picker's corners also appear rounded on Android
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5, // For Android shadow
    backgroundColor: "#ffffff", // Consider a background color to match your app theme
  },
  pickerStyle: {
    width: "100%", // Ensure the Picker fills its container
    color: "#444444", // Adjust the text color to fit your theme
  },

  adminActions: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
  adminButton: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 4,
    elevation: 3, // For Android shadow
    shadowOpacity: 0.2, // For iOS shadow
  },
  saveCommentButton: {
    backgroundColor: "#FD7E14", // Or any color that suits your app design
  },
  deleteButton: {
    backgroundColor: "red", // A common color indicating a delete action
  },
  adminButtonText: {
    color: "white",
    textAlign: "center",
  },

  reportUser: {
    fontSize: 14,
    marginTop: 5,
  },
});

