import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Text } from 'react-native';
import { Provider as PaperProvider, DefaultTheme, TextInput } from 'react-native-paper';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const customTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'tomato',
    accent: 'yellow',
  },
  icons: {
    back: <MaterialCommunityIcons name="arrow-left" size={24} />,
  },
};

const Sandbox = () => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState({ hours: 12, minutes: 14 });
  const [visible, setVisible] = useState(false);
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [location, setLocation] = useState(''); // State to store location input by the user

  const onDismiss = () => {
    setVisible(false);
    setTimePickerVisible(false);
  };

  const onConfirmDate = (selectedDate) => {
    setVisible(false);
    if (selectedDate instanceof Date) {
      setDate(selectedDate);
    } else {
      console.error('Selected date is not a Date object:', selectedDate);
      setDate(new Date());
    }
  };

  const onConfirmTime = ({ hours, minutes }) => {
    setTimePickerVisible(false);
    setTime({ hours, minutes });
  };

  return (
    <PaperProvider theme={customTheme}>
      <View style={styles.container}>
        <TextInput
          label="Location"
          value={location}
          onChangeText={text => setLocation(text)}
          mode="outlined"
          style={styles.input}
        />
        <Pressable style={styles.button} onPress={() => setVisible(true)}>
          <Text style={styles.buttonText}>Pick a date</Text>
        </Pressable>
        <Pressable style={styles.button} onPress={() => setTimePickerVisible(true)}>
          <Text style={styles.buttonText}>Pick time</Text>
        </Pressable>
        <Text style={styles.text}>Selected Date: {date.toLocaleDateString()}</Text>
        <Text style={styles.text}>Selected Time: {`${time.hours}:${time.minutes}`}</Text>
        <DatePickerModal
          mode="single"
          visible={visible}
          onDismiss={onDismiss}
          date={date}
          onConfirm={onConfirmDate}
          saveLabel="Save"
          label="Select date"
        />
        <TimePickerModal
          visible={timePickerVisible}
          onDismiss={onDismiss}
          onConfirm={onConfirmTime}
          hours={time.hours}
          minutes={time.minutes}
        />
      </View>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: 'tomato',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
  },
  text: {
    margin: 10,
  },
  input: {
    marginBottom: 20,
    width: '90%', // Ensure the input field is not too wide
  },
});

export default Sandbox;
