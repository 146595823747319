// leaderboard virkar... 22.ágúst'24

// Virkar í WEB og iphone. css good.  

import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  ScrollView,
  Button,
  TouchableOpacity,
} from "react-native";
import { collectionGroup, query, where, onSnapshot,doc,getDoc } from "firebase/firestore";
import { database } from "../componenets/firebase"; // Correct the path if necessary
import moment from "moment";
// import { FontAwesome } from '@expo/vector-icons';

import { MaterialCommunityIcons } from '@expo/vector-icons';



const getInitials = (name) => {
  const initials = name
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toUpperCase();
  return initials.slice(0, 2); // Only return the first two initials
};


const Leaderboard = ({ route }) => {
  const { mainGameId } = route.params;
  const [dates, setDates] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [rowBackgroundColor, setRowBackgroundColor] = useState("grey"); // Use the scheme name
  const [gameName, setGameName] = useState(""); // State to store the game name


   // Find game name
   // Function to fetch the game name
   const fetchGameName = async () => {
    try {
      const gameRef = doc(database, "MainGame", mainGameId);
      const gameSnapshot = await getDoc(gameRef);

      if (gameSnapshot.exists()) {
        const gameData = gameSnapshot.data();
        setGameName(gameData.name);
      }
    } catch (error) {
      console.error("Error fetching game name:", error);
    }
  };




  const fetchAndProcessData = useCallback(async () => {
    const playersQuery = query(
      collectionGroup(database, "Players"),
      where("mainGameId", "==", mainGameId)
    );

    const unsubscribe = onSnapshot(playersQuery, (querySnapshot) => {
      let games = {};
      let players = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const gameDateFormatted = moment(data.gamedate).format("YYYY-MM-DD");
        const gameKey = `${gameDateFormatted}-${data.gameIndex}`;

        // Create a unique key for each player based on their Name and userId
        const playerKey = `${data.Name}-${data.userId}`;

        if (data.scoreHome != null && data.scoreAway != null) {
          games[gameKey] = {
            date: gameDateFormatted,
            index: data.gameIndex,
            scoreHome: data.scoreHome,
            scoreAway: data.scoreAway
          };
        }

        if (!players[playerKey]) {
          players[playerKey] = {
            Name: data.Name,
            email: data.email || '',
            userId: data.userId || '',
            games: {},
            totalPoints: 0,
            totalGF: 0,
            totalGA: 0,
            totalGD: 0
          };
        }

        if (data.totalPoints != null) {
          players[playerKey].games[gameKey] = data.totalPoints;
          players[playerKey].totalPoints += data.totalPoints;
          if (data.team === "Home") {
            players[playerKey].totalGF += data.scoreHome !== null ? data.scoreHome : 0;
            players[playerKey].totalGA += data.scoreAway !== null ? data.scoreAway : 0;
          } else {
            players[playerKey].totalGF += data.scoreAway !== null ? data.scoreAway : 0;
            players[playerKey].totalGA += data.scoreHome !== null ? data.scoreHome : 0;
          }
          players[playerKey].totalGD = players[playerKey].totalGF - players[playerKey].totalGA;
        }
      });

      const gamesArray = Object.values(games).sort((a, b) => {
        return moment(a.date).diff(moment(b.date)) || a.index - b.index;
      });
      setDates(gamesArray);

      // Sort players based on the specified order: Points, GD, GF, GA, Name
      const playersArray = Object.values(players).sort((a, b) => {
        if (b.totalPoints !== a.totalPoints) return b.totalPoints - a.totalPoints;
        if (b.totalGD !== a.totalGD) return b.totalGD - a.totalGD;
        if (b.totalGF !== a.totalGF) return b.totalGF - a.totalGF;
        return a.Name.localeCompare(b.Name);
      });
      
      // Assign places with tie handling
      let placeNumber = 1;
      for (let i = 0; i < playersArray.length; i++) {
        if (
          i > 0 &&
          playersArray[i].totalPoints === playersArray[i - 1].totalPoints &&
          playersArray[i].totalGD === playersArray[i - 1].totalGD &&
          playersArray[i].totalGF === playersArray[i - 1].totalGF
        ) {
          playersArray[i].place = "-";  // Assign "-" for tied places
        } else {
          playersArray[i].place = placeNumber;
        }
        placeNumber++;
      }
      
      setPlayersData(playersArray);
      
    });

    return () => unsubscribe();
  }, [mainGameId]);



  useEffect(() => {
    fetchGameName();
    fetchAndProcessData();
  }, [mainGameId, fetchAndProcessData]);
  

  // Function to get the header background color based on the current color scheme
  const getHeaderBackgroundColor = () => {
    // Lookup the color scheme directly by name
    const scheme = colorSchemes[rowBackgroundColor]; // Use the state value as a key
    return scheme ? scheme.header : colorSchemes.grey.header; // Default to grey if not found
  };

  const getRowBackgroundColor = (index) => {
    const scheme = colorSchemes[rowBackgroundColor]; // Direct lookup by state value
    if (!scheme) {
      return { backgroundColor: colorSchemes.grey.main }; // Default to grey if scheme not found
    }

    // Determine the background color based on the row index
    return {
      backgroundColor: index % 2 === 0 ? scheme.main : scheme.alternate,
    };
  }; 


  
 


  // Render rank with special icons for top 3 and numbers for others
  const renderRank = (index) => {
    switch (index) {
      case 0:
        return (          
          <View style={styles.iconWithText}>
          <MaterialCommunityIcons name="medal" size={20} color="#FFD700" />            
            <Text style={styles.iconText}>1</Text>
          </View>
        );
      case 1:
        return (
          <View style={styles.iconWithText}>
          <MaterialCommunityIcons name="medal" size={24} color="#C0C0C0" />                        
            <Text style={styles.iconText}>2</Text>
          </View>
        );
      case 2:
        return (
          <View style={styles.iconWithText}>
          <MaterialCommunityIcons name="medal" size={24} color="#DFC07F" />                        
            <Text style={styles.iconText}>3</Text>
          </View>
        );
      default:
        return <Text style={styles.rankText}>{index + 1}</Text>; // "+1" because index is 0-based
    }
  };
  
 


  return (
    <ScrollView horizontal={true}> 


      <View style={{ flexDirection: "column" }}>





      <View style={{ flexDirection: "row", padding: 10 }}>

 
</View>


      <Text style={styles.title}>Leaderboard for {gameName} </Text>

 
      

   
       
      <FlatList
  data={playersData}
  keyExtractor={(item) => item.userId}
  ListHeaderComponent={() => (
    <View>
      {/* First row for Name, Points, GF, GA, GD, and Dates */}
      <View style={[styles.row, { backgroundColor: getHeaderBackgroundColor() }]}>
        <Text style={styles.placeCell}>#</Text>
        <Text style={styles.nameCell}>Name</Text>
        <Text style={styles.headerCell}>Points</Text>
        <Text style={styles.headerCell}>GF</Text>
        <Text style={styles.headerCell}>GA</Text>
        <Text style={styles.headerCell}>GD</Text>
        {dates.map(({ date, index }, arrayIndex) => (
          <View key={`date-${index}-${arrayIndex}`} style={styles.headerCell}>
            <Text>{moment(date).format("D.MMM'YY")}</Text>
            {dates.filter(d => d.date === date).length > 1 && <Text>{`Game ${index}`}</Text>}
          </View>
        ))}
      </View>

      {/* Second row exclusively for Scores */}
      <View style={[styles.row, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}>
        {/* Empty views to skip the initial headers */}
        <View style={[styles.placeCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}></View>
        <View style={[styles.nameCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}></View>
        <View style={[styles.headerCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}></View>
        <View style={[styles.headerCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}></View>
        <View style={[styles.headerCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}></View>
        <View style={[styles.headerCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}></View>
        
        {/* Mapping over dates to display scores */}
        {dates.map(({ date, index, scoreHome, scoreAway }, arrayIndex) => {
          const isValidScore = score => score !== null && score !== "-";
          const displayScoreHome = isValidScore(scoreHome) ? `H: ${scoreHome}` : "";
          const displayScoreAway = isValidScore(scoreAway) ? `A: ${scoreAway}` : "";
          const displayScore = [displayScoreHome, displayScoreAway].filter(Boolean).join(" - ");
          const scoreKey = `score-${index}-${arrayIndex}`;

          return (
            <View key={scoreKey} style={[styles.headerCell, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}>
              <Text style={styles.scoreDisplay}>{displayScore}</Text>
            </View>
          );
        })}
      </View>
    </View>
  )}
  renderItem={({ item, index }) => (
    <View style={[styles.row, getRowBackgroundColor(index)]}>
      <View style={styles.rankCell}>{renderRank(index)}</View>
      <View style={styles.nameEmailContainer}>
        <View style={styles.initialsContainer}>
          <Text style={styles.initialsText}>{getInitials(item.Name)}</Text>
        </View>
        <Text style={styles.nameCell} numberOfLines={1} ellipsizeMode="tail">
          {item.Name}
        </Text>
      </View>
      <Text style={styles.pointsCell}>{item.totalPoints}</Text>
      <Text style={styles.pointsCell}>{item.totalGF}</Text>
      <Text style={styles.pointsCell}>{item.totalGA}</Text>
      <Text style={styles.pointsCell}>{item.totalGD}</Text>
      {dates.map((game, gameIndex) => {
        const gameKey = `${game.date}-${game.index}`;
        const gamePoints = item.games[gameKey] !== undefined ? item.games[gameKey] : "-";
        return (
          <Text key={`${item.Name}-game-${gameIndex}`} style={[styles.pointsCell, { textAlign: 'center' }]}>
            {gamePoints}
          </Text>
        );
      })}
    </View>
  )}
  ListFooterComponent={<View style={{ marginBottom: 20 }} />}
/>



      </View>
    </ScrollView>
  );
};

const colorSchemes = {
  grey: {
    main: "#f2f2f2",
    alternate: "#d9d9d9",
    header: "#cccccc",
  },
  blue: {
    //main: '#007bff', // Vibrant blue
    main: "#add8e6",
    alternate: "#89CFF0",
    header: "#99ccff",
  },
  pink: {
    main: "#F883B0", // A softer, toned-down Barbie pink for main rows
    alternate: "#FA9AB7", // A slightly lighter pink, maintaining the playful theme for alternate rows
    header: "#E5288C", // A dee

   
  },
  dark: {
    main: "#FFC0CB", // Dark grey, almost black
    alternate: "#ff8080", // Slightly lighter dark grey
    header: "#ff6666", // Almost black for header
  },
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    paddingVertical: 1, // Reduced padding to decrease the gap
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
 
  placeCell: {
    minWidth: 50,
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    justifyContent: 'center',
    alignItems: 'center',
  },
  pointsCell: {
    minWidth: 120,
    textAlign: "center",
    fontSize: 14,
    alignSelf: 'center',
    paddingVertical: 3, // Further reduced padding to decrease the gap
  },
  nameCell: {
    minWidth: 150,
    maxWidth: 100,
    fontSize: 14,
    alignSelf: 'center',
    paddingVertical: 3, // Reduced padding to decrease the gap
    overflow: 'hidden',
  },
  rankCell: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  nameEmailContainer: {
    minWidth: 110,
    maxWidth: 150,
    flexDirection: 'row', // Adjusted to row to place initials next to name
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 5, // Reduced margin to decrease the gap between the title and the header
    color: "#333",
  },
  scoreDisplay: {
    color: 'blue',
    fontSize: 12,
    fontWeight: 'bold',
    paddingVertical: 1, // Reduced padding to decrease the gap

  },
  initialsContainer: {
    width: 30,  // Smaller width for the initials circle
    height: 30, // Smaller height for the initials circle
    borderRadius: 15, // Adjust borderRadius to keep it circular
    backgroundColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5, // Reduced space between initials and name
  },
  initialsText: {
    color: '#fff',
    fontSize: 14, // Reduced font size for initials
    fontWeight: 'bold',
  },
  iconWithText: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative', // Ensure the text is positioned relative to the icon
    width: 30, // Adjust the width if needed to better fit the smaller initials
  },
  iconNumber: {
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: 12,
    color: 'black',
    top: 4, // Adjust top positioning to center the number within the icon
  },
  initialsContainer: {
    width: 30,  // Smaller width for the initials circle
    height: 30, // Smaller height for the initials circle
    borderRadius: 15, // Adjust borderRadius to keep it circular
    backgroundColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5, // Reduced space between initials and name
  },
  initialsText: {
    color: '#fff',
    fontSize: 14, // Reduced font size for initials
    fontWeight: 'bold',
  },
  headerCell: {
    minWidth: 120, 
    textAlign: "center",
    fontWeight: "bold",
    //backgroundColor: "#d6d6d6",
    paddingVertical: 0, // Reduced padding to decrease the gap
    marginVertical: 0,  // Remove any vertical margin to tighten the space
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
  },
  pointsCell: {
    minWidth: 120,
    textAlign: "center",
    fontSize: 14,
    paddingVertical: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  
  


});





export default Leaderboard;





