import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  Platform,
} from "react-native";
import LeagueTable from "./LeagueTable";
import LeaderBoard from "./LeaderBoard";
import PointChart from "./PointChart";
import LeaderboardTest from "./LeaderBoardTest";

import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();

import { database } from "../componenets/firebase";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  updateDoc,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

//PointChart

const GameDashboard = ({ navigation, route }) => {
  const [pointsEnabled, setPointsEnabled] = useState(false);
  const [playerData, setPlayerData] = useState([]);
  const [mainGameId, setmainGame] = useState(route.params.mainGameId);
  const [TheGameId, setTheGameId] = useState(route.params.TheGameId);
  const [GameDate, setGameDate] = useState(route.params.GameDate);

  const [isAdmin, setIsAdmin] = useState(false);
  const [gameMode, setGameMode] = useState(null);

  const [content, setContent] = useState("LeagueTable");

  const handleContentChange = (contentName) => {
  //  console.log(`Navigating to ${contentName}...`);
    setContent(contentName);
  };

  useEffect(() => {
    const fetchIsAdminStatus = async () => {
      try {
        const mainGameDoc = doc(database, "MainGame", mainGameId);
        const mainGameSnapshot = await getDoc(mainGameDoc);
        const { memberOfGame, mode } = mainGameSnapshot.data(); // Get the mode field
    //    console.log("memberOfGame:", memberOfGame);

        // Update the gameMode state
        setGameMode(mode); // Set the gameMode state to whatever is in Firestore

        const currentUser = auth.currentUser;
     //   console.log("currentUser:", currentUser);

        if (currentUser) {
          const currentUserId = currentUser.uid;
        //  console.log("currentUserId:", currentUserId);

          const currentUserData = memberOfGame.find(
            (member) => member.uid === currentUserId
          );

          if (currentUserData) {
            const adminStatus = currentUserData.isAdmin;
          //  console.log("adminStatus:", adminStatus);
            setIsAdmin(adminStatus === true);
          } else {
          //  console.log("currentUser not found in memberOfGame array");
          }
        }
      } catch (error) {
       // console.error("Error in fetchIsAdminStatus:", error);
      }
    };

    fetchIsAdminStatus();
  }, []);

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor:
                  content === "LeagueTable" ? "#6AADFF" : "#007AFF",
              },
              content === "LeagueTable" ? styles.buttonShadow : null,
            ]}
            onPress={() => handleContentChange("LeagueTable")}
          >
            <Text style={styles.buttonText}>Summary</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor:
                  content === "LeaderBoard" ? "#6AADFF" : "#007AFF",
              },
              content === "LeaderBoard" ? styles.buttonShadow : null,
            ]}
            onPress={() => handleContentChange("LeaderBoard")}
          >
            <Text style={styles.buttonText}>LeaderBoard</Text>
          </TouchableOpacity>


          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor:
                  content === "PointChart" ? "#6AADFF" : "#007AFF",
              },
              content === "PointChart" ? styles.buttonShadow : null,
            ]}
            onPress={() => handleContentChange("PointChart")}
          >
            <Text style={styles.buttonText}>Point Chart</Text>
          </TouchableOpacity>


          {auth.currentUser?.email === "dondanni@hotmail.com" && (
  <TouchableOpacity
    style={[
      styles.button,
      {
        backgroundColor:
          content === "LeaderBoardTest" ? "#6AADFF" : "#007AFF",
      },
      content === "LeaderBoardTest" ? styles.buttonShadow : null,
    ]}
    onPress={() => handleContentChange("LeaderBoardTest")}
  >
    <Text style={styles.buttonText}>LeaderboardTest</Text>
  </TouchableOpacity>
)}

          
     
        </View>
      </View>

      <ScrollView
  contentContainerStyle={styles.scrollContainer}
  horizontal={true}
  showsHorizontalScrollIndicator={true}
  showsVerticalScrollIndicator={true}
>
  <View style={styles.leagueTableContainer}>
    {content === "LeagueTable" ? (
      <LeagueTable
        GameDate={GameDate}
        mainGameId={mainGameId}
        TheGameId={TheGameId}
        route={route}
      />
    ) : content === "PointChart" ? (
      <PointChart
        GameDate={GameDate}
        mainGameId={mainGameId}
        TheGameId={TheGameId}
        route={route}
      />
    ) : content === "LeaderBoardTest" ? (
      <LeaderboardTest
        GameDate={GameDate}
        mainGameId={mainGameId}
        TheGameId={TheGameId}
        route={route}
      />
    ) : (
      <LeaderBoard
        GameDate={GameDate}
        mainGameId={mainGameId}
        TheGameId={TheGameId}
        route={route}
      />
    )}
  </View>
</ScrollView>

    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  button: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
    margin: 10,
  },
  buttonText: {
    color: "#fff",
    fontSize: Platform.OS === "ios" ? 13 : 18 
  },
  leagueTableContainer: {
    marginTop: 20,
  },
  scrollContainer: {
    flexGrow: 1,
  },
  buttonShadow: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
    margin: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 6,
      height: 6,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },
});

export default GameDashboard;
