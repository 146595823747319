import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Switch,
  FlatList,
  Alert,
  Platform, // Add Platform import
  Modal, // Add Modal import
} from "react-native";
import uuid from "react-native-uuid";
import { database } from "../componenets/firebase";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  deleteDoc,
  setDoc,
  getFirestore,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";

const AddPlayerScreen = ({ navigation, route }) => {
  const currentMainGameId = route.params.mainGameId.toString();
  const [playerName, setPlayerName] = useState("");
  const [email, setEmail] = useState("");
  const [players, setPlayers] = useState([]);
  const [editablePlayers, setEditablePlayers] = useState(false);
  const [showWebAlert, setShowWebAlert] = useState(false); // Add state for web alert visibility

  const [isOwner, setIsOwner] = useState(false);
  
  const owner = players.find(player => player.isOwner);

  const ownerPlayer = players.find(player => player.isOwner);
const ownerEmail = ownerPlayer ? ownerPlayer.email : "No owner found";




  const [hasPoints, setHasPoints] = useState(false);
  const [winPoints, setWinPoints] = useState("0");
  const [losePoints, setLosePoints] = useState("0");
  const [drawPoints, setDrawPoints] = useState("0");
  const [attendancePoints, setAttendancePoints] = useState("0");
  const [absentPoints, setAbsentPoints] = useState("0");
  const [TeenGoalPoints, setTeenGoalPoints] = useState("0");
  
  const [enablePoints, setEnablePoints] = useState(false);

  const handleEnablePointsToggle = async (value) => {
    const gameRef = doc(database, "MainGame", currentMainGameId);
    const firestore = getFirestore();

    await setDoc(gameRef, { pointsEnabled: value }, { merge: true });

    setEnablePoints(value);
  };


  const handleWebAlertResponse = async (response) => {
    setShowWebAlert(false);
    if (response === "delete") {
      const docRef = doc(database, "MainGame", currentMainGameId);
      await deleteDoc(docRef);
      navigation.navigate("My Games");
    }
  };
  



// const deleteMainGame = () => {
//   if (Platform.OS === "web") {
//     setShowWebAlert(true);
//   } else {
//     Alert.alert(
//       "Warning",
//       "Are you sure you want to delete this game? This action cannot be undone.",
//       [
//         {
//           text: "Cancel",
//           onPress: () => console.log("Cancel Pressed"),
//           style: "cancel",
//         },
//         {
//           text: "Yes, Delete",
//           onPress: async () => {
//             const docRef = doc(database, "MainGame", currentMainGameId);
//             await deleteDoc(docRef);
//             navigation.navigate("My Games");

//             //navigation.goBack(); // Navigate back after deletion
//           },
//           style: "destructive",
//         },
//       ],
//       { cancelable: true }
//     );
//   }
// };





  const handleSubmit = async () => {
    const gameRef = doc(database, "MainGame", currentMainGameId);
    const firestore = getFirestore();

    if (hasPoints) {
      await setDoc(
        gameRef,
        {
          winPoints: parseInt(winPoints),
          losePoints: parseInt(losePoints),
          drawPoints: parseInt(drawPoints),
          attendancePoints: parseInt(attendancePoints),
          absentPoints: parseInt(absentPoints),
          TeenGoalPoints: parseInt(TeenGoalPoints), // Add this line
          pointsEnabled: true,
        },
        { merge: true }
      );
    } else {
      await setDoc(
        gameRef,
        {
          pointsEnabled: false,
        },
        { merge: true }
      );
    }
};

  const auth = getAuth();

  // Add another useEffect to fetch data from Firestore.
 useEffect(() => {
  const gameRef = doc(database, "MainGame", currentMainGameId);

  const unsubscribe = onSnapshot(gameRef, (docSnap) => {
    if (docSnap.exists()) {
      const gameData = docSnap.data();
      if (gameData.pointsEnabled !== undefined) {
        setEnablePoints(gameData.pointsEnabled);
      }
      // Fetch the points from Firestore and save them to the state
      if (gameData.winPoints !== undefined) {
        setWinPoints(gameData.winPoints);
      }
      if (gameData.losePoints !== undefined) {
        setLosePoints(gameData.losePoints);
      }
      if (gameData.drawPoints !== undefined) {
        setDrawPoints(gameData.drawPoints);
      }
      if (gameData.attendancePoints !== undefined) {
        setAttendancePoints(gameData.attendancePoints);
      }
      if (gameData.absentPoints !== undefined) {
        setAbsentPoints(gameData.absentPoints);
      }
      if (gameData.TeenGoalPoints !== undefined) {
        setTeenGoalPoints(gameData.TeenGoalPoints); // Add this line
      }
  
    }
  });
  // Cleanup function to unsubscribe from the listener when the component is unmounted.

  return () => unsubscribe();
}, [currentMainGameId]);


  useEffect(() => {
    const currentUserId = auth.currentUser ? auth.currentUser.uid : "";
    const docRef = doc(database, "MainGame", currentMainGameId);
    onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const gameData = docSnap.data();
        const currentPlayer = gameData.memberOfGame.find(
          (player) => player.uid === currentUserId
        );
        if (currentPlayer && currentPlayer.isOwner) {
          setIsOwner(true);
        }
        setPlayers(
          gameData.memberOfGame.map((player, index) => ({
            id: index + 1,
            name: player.name,
            email: player.email,
            uid: player.uid,
            isAdmin: player.isAdmin || false,
            isOwner: player.isOwner || false, // Add isOwner field
          }))
        );
        // Add this line to fetch and set the value of "pointsEnabled"
        setHasPoints(gameData.pointsEnabled || false);
       } else {
       // console.log("No such document!");
      }
    });
  }, [currentMainGameId]); // Removed currentUserId from dependency array

//   const handleEditButton = () => {
//     setEditablePlayers(!editablePlayers);
//   };

//   const updatePlayersInDatabase = async (mainGameId, updatedPlayers) => {
//     const docRef = doc(database, "MainGame", mainGameId);
//     await updateDoc(docRef, {
//       memberOfGame: updatedPlayers.map((player) => ({
//         uid: player.uid,
//         email: player.email,
//         name: player.name,
//         isAdmin: player.isAdmin,
//       })),
//     });
//   };

//   const handleToggleAdmin = (playerId, value) => {
//     handleEditPlayer(playerId, { isAdmin: value });
//   };

  const renderPlayer = ({ item: player }) => (
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.tableNumber]}>{player.id}</Text>
      <TextInput
        style={[
          styles.tableCell,
          styles.inputCell,
          editablePlayers ? styles.editableName : null,
        ]}
        defaultValue={player.name}
        onChangeText={(text) =>
          editablePlayers && handleEditPlayer(player.id, { name: text })
        }
        editable={editablePlayers}
      />
      <TextInput
        style={[
          styles.tableCell,
          styles.inputCell,
          editablePlayers ? styles.editableEmail : null,
        ]}
        defaultValue={player.email}
        onChangeText={(text) =>
          editablePlayers && handleEditPlayer(player.id, { email: text })
        }
        editable={editablePlayers}
      />
      
      {/* <View style={[styles.tableCell, styles.switchCell]}>
        <Switch
          value={player.isAdmin}
          onValueChange={(value) => handleToggleAdmin(player.id, value)}
          trackColor={{ false: "#ccc", true: "green" }}
        />
      </View> */}
      
      {/* {editablePlayers && (
        <View style={styles.tableCell}>
          <Button
            title="Remove"
            onPress={() => handleRemovePlayer(player.id)}
          />
        </View>
      )} */}
    </View>
  );

  return (
    <SafeAreaView style={styles.container}>
        <View>
            <Text style={styles.title}>Owner of this game is: {owner ? owner.name : 'Not set'} - {ownerEmail} </Text>
            
            {/* {isOwner && <Button title="Delete Game" onPress={deleteMainGame} />} */}

             
            {/* {isOwner &&

            <View style={styles.deleteButtonContainer}>
                <View style={styles.deleteButtonWrapper}>
                    <Button
                        title="Delete this Game"
                        onPress={deleteMainGame}
                        color="red"
                    />
                </View>
            </View>
            } */}
            


            <View style={styles.mainContent}>
                {/* <Text style={styles.title}>Add Player Name</Text> */}

                {Platform.OS === "web" && (
                    <Modal
                        visible={showWebAlert}
                        onRequestClose={() => setShowWebAlert(false)}
                    >
                        <View style={styles.modal}>
                            <Text>Are you sure you want to delete this game?</Text>
                            <View style={styles.buttonContainer}>
                                <Button
                                    title="Cancel"
                                    onPress={() => setShowWebAlert(false)}
                                />
                                <Button
                                    title="Delete"
                                    onPress={() => handleWebAlertResponse("delete")}
                                />
                            </View>
                        </View>
                    </Modal>
                )}

                <Text style={styles.title}>Points in Game</Text>
                <Text> To get players more committed to attend and win you can give extra points or subtract them. This option makes the game more interesting but is not necessary.   </Text>

                <View style={styles.switchContainer}>
                    <Switch
                        value={enablePoints}
                        onValueChange={handleEnablePointsToggle}
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={enablePoints ? "#f5dd4b" : "#f4f3f4"}
                    />
                    <Text style={styles.title}>
                        Enable Points: {enablePoints ? "Enabled" : "Disabled"} 
                    </Text>
                </View>

                <Text style={styles.infoText}>
                    If points are disabled then score will be used in the calculation.
                </Text>

                {hasPoints && (
                  <>
            <View style={styles.pointsContainer}>
              <Text style={styles.pointsLabel}>Win points:</Text>
              <TextInput
                style={styles.pointsInput}
                onChangeText={setWinPoints}
                value={winPoints}
                placeholder="Win Points"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.pointsContainer}>
              <Text style={styles.pointsLabel}>Lose points:</Text>
              <TextInput
                style={styles.pointsInput}
                onChangeText={setLosePoints}
                value={losePoints}
                placeholder="Lose Points"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.pointsContainer}>
              <Text style={styles.pointsLabel}>Draw points:</Text>
              <TextInput
                style={styles.pointsInput}
                onChangeText={setDrawPoints}
                value={drawPoints}
                placeholder="Draw Points"
                keyboardType="numeric"
              />
            </View>


            <View style={styles.pointsContainer}>
              <Text style={styles.pointsLabel}>Attendance points:</Text>
              <TextInput
                style={styles.pointsInput}
                onChangeText={setAttendancePoints}
                value={attendancePoints}
                placeholder="Attendance Points"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.pointsContainer}>
              <Text style={styles.pointsLabel}>Absent points:</Text>
              <TextInput
                style={styles.pointsInput}
                onChangeText={setAbsentPoints}
                value={absentPoints}
                placeholder="Absent Points"
                keyboardType="numeric"
              />
            </View>

            <View style={styles.pointsContainer}>
        <Text style={styles.pointsLabel}>Ten Goal Points:</Text>
        <TextInput
          style={styles.pointsInput}
          onChangeText={setTeenGoalPoints}
          value={TeenGoalPoints}
          placeholder="Ten Goal Points"
          keyboardType="numeric"
        />
      </View>


          </>
                )}

                <Button title="Submit" onPress={handleSubmit} />
            </View>
        </View>
    </SafeAreaView>
);
};



export default AddPlayerScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //padding: 16,
    backgroundColor: "#fff",
    height: "100%", // add this
    overflow: "auto", // and this
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
  },
  input: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
    fontSize: 16,
  },
  label: {
    fontSize: 16,
    marginBottom: 8,
  },
  playersList: {
    marginTop: 16,
  },
  tableTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
    backgroundColor: "#f5f5f5",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ccc",
  },
  tableHeader: {
    fontSize: 16,
    flex: 1,
    textAlign: "center",
    fontWeight: "bold", // Add fontWeight to make it bold
  },
  tableCell: {
    fontSize: 16,
    flex: 1,
    textAlign: "center",
  },

  tableNumber: {
    textAlign: "center",
  },
  editableName: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    padding: 8,
    fontSize: 16,
    flex: 1,
    textAlign: "center",
  },
  editableEmail: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    padding: 8,
    fontSize: 16,
    flex: 1,
    textAlign: "center",
  },
  playerItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  playerName: {
    fontSize: 16,
    marginRight: 8,
  },
  playerEmail: {
    fontSize: 12,
    color: "#666",
  },

  inputCell: {
    borderWidth: 1,
    borderColor: "transparent",
    borderRadius: 4,
    padding: 8,
    fontSize: 16,
  },
  switchCell: {
    justifyContent: "center",
    alignItems: "center", // Add this line to center the Switch component
  },

  // Add styles for the web modal
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    width: "50%",
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
  },
  modalButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteButton: {
    position: "absolute",
    top: 16,
    right: 16,
    //zIndex: 1, // To make sure the button is above other elements
  },

  deleteButtonContainer: {
    position: "absolute",
    top: Platform.OS === "ios" ? 10 : Platform.OS === "android" ? 16 : 8,
    right: Platform.OS === "web" ? 8 : 16,
    zIndex: 1,
  },
  deleteButtonWrapper: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 4,
  },
  mainContent: {
    marginTop: Platform.OS === "ios" ? 40 : 20,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  infoText: {
    fontSize: 12,
    color: "gray",
    marginBottom: 20,
  },

  pointsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  pointsLabel: {
    flex: 1,
    fontSize: 16,
  },
  pointsInput: {
    flex: 2,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
    fontSize: 16,
  },
});
