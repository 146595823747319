import React, { useState, useEffect } from 'react';
import { View, Text, Switch, Alert } from 'react-native';
import * as Notifications from 'expo-notifications';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const db = getFirestore();

const NotificationSettings = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const userId = getAuth().currentUser?.uid;

  // Check notification permissions on component mount
  useEffect(() => {
    console.log('Checking notification permissions...');
    checkNotificationPermissions();
  }, []);

  const checkNotificationPermissions = async () => {
    const { status } = await Notifications.getPermissionsAsync();
    console.log('Current notification permission status:', status);
    setIsEnabled(status === 'granted');
  };

  const toggleSwitch = async () => {
    console.log('Toggle switch activated. Current status:', isEnabled);
    if (!isEnabled) {
      const { status } = await Notifications.requestPermissionsAsync();
      console.log('Requested notification permission, received status:', status);
      if (status !== 'granted') {
        Alert.alert('Permission Required', 'Please enable notifications in your settings.');
        return;
      }
    }
    const newStatus = !isEnabled;
    setIsEnabled(newStatus);
    handleNotificationPreference(newStatus);
  };

  const handleNotificationPreference = async (enabled) => {
    console.log('Handling notification preference. Notifications enabled:', enabled);

    try {
      if (userId) {
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, { notification: enabled });
        console.log('Notification status updated in Firebase:', enabled);
      }

      if (enabled) {
        // Enable notifications logic
        await Notifications.scheduleNotificationAsync({
          content: { title: 'Notifications Enabled', body: 'You will receive notifications.' },
          trigger: { seconds: 1 },
        });
        console.log('Scheduled a local notification as notifications are enabled.');
      } else {
        // Disable notifications logic
        await Notifications.cancelAllScheduledNotificationsAsync();
        console.log('Canceled all scheduled notifications as notifications are disabled.');
      }
    } catch (error) {
      console.error('Error updating notification preference:', error);
    }
  };

  return (
    <View style={{ padding: 20 }}>
      <Text>Enable Notifications</Text>
      <Switch
        trackColor={{ false: '#767577', true: '#81b0ff' }}
        thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
        ios_backgroundColor="#3e3e3e"
        onValueChange={toggleSwitch}
        value={isEnabled}
      />
    </View>
  );
};

export default NotificationSettings;
