import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Pressable,
  Alert,
  Platform  // Import platform for detecting the current platform
} from 'react-native';
import { getDoc, doc, collection, getDocs, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";

import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { getAuth } from 'firebase/auth';




// Set notification handler to define behavior when a notification is received
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

// Function to request notification permission on the web and send a notification
const sendWebNotification = (title, body) => {
  if (!('Notification' in window)) {
    Alert.alert('Web Notification', 'This browser does not support notifications.');
    return;
  }

  // Check if the user has already granted permission
  if (Notification.permission === 'granted') {
    new Notification(title, { body });
  } else if (Notification.permission !== 'denied') {
    // Request permission from the user
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        new Notification(title, { body });
      }
    });
  }
};

const Reminder = ({ route }) => {
  const [gameDates, setGameDates] = useState([]);
  const [playerGameData, setPlayerGameData] = useState([]);
  const database = getFirestore();
  const currentMainGameId = route.params.mainGameId.toString();  // Get MainGameId from route

  // Fetch game data and players
  useEffect(() => {
    const fetchGameDataAndPlayers = async () => {
      try {
        // Log the current Game ID
        console.log("Fetching data for Game ID:", currentMainGameId);
  
        // 1. Get the MainGame document (to fetch memberOfGame)
        const mainGameDocRef = doc(database, 'MainGame', currentMainGameId);
        const mainGameSnapshot = await getDoc(mainGameDocRef);
  
        if (mainGameSnapshot.exists()) {
          const mainGameData = mainGameSnapshot.data();
          const players = mainGameData.memberOfGame || [];
  
          // 2. Fetch the 'TheGame' sub-collection to get game details (date, time)
          const gamesCollectionRef = collection(database, 'MainGame', currentMainGameId, 'TheGame');
          const gamesSnapshot = await getDocs(gamesCollectionRef);
  
          let combinedData = [];
          let gameDatesWithTime = [];
  
          await Promise.all(gamesSnapshot.docs.map(async (gameDoc) => {
            const gameData = gameDoc.data();
            const gameDate = gameData.date;
            const gameTime = gameData.time;
  
            if (!gameDatesWithTime.some(game => game.date === gameDate && game.time === gameTime)) {
              gameDatesWithTime.push({ date: gameDate, time: gameTime });
            }
  
            await Promise.all(players.map(async (player) => {
              let expoPushToken = 'No Token';
  
              // Log the User ID for each player
              console.log("Fetching data for User ID:", player.uid);
  
              if (player.uid) {
                const userDocRef = doc(database, 'users', player.uid);
                const userSnapshot = await getDoc(userDocRef);
  
                if (userSnapshot.exists()) {
                  const userData = userSnapshot.data();
                  expoPushToken = userData.expoPushToken || 'No Token';
                }
              }
  
              const isPlayerAlreadyInList = combinedData.some(item => item.uid === player.uid);
  
              if (!isPlayerAlreadyInList) {
                combinedData.push({
                  uid: player.uid,
                  name: player.name,
                  email: player.email || 'No Email',
                  expoPushToken: expoPushToken,
                });
              }
            }));
          }));
  
          setGameDates(gameDatesWithTime);
          setPlayerGameData(combinedData);
        } else {
          console.log("Main game document not found.");
        }
      } catch (error) {
        console.error("Error fetching game data and players: ", error);
      }
    };
  
    fetchGameDataAndPlayers();
  }, [currentMainGameId]);

  
// find the current user's name and set it to the state

const [currentUserName, setCurrentUserName] = useState(null);


  useEffect(() => {
    const fetchCurrentUserName = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;
  
        if (currentUser) {
          const userDocRef = doc(database, 'users', currentUser.uid);
          const userSnapshot = await getDoc(userDocRef);
  
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setCurrentUserName(userData.name || 'Unknown');  // Set the current user's name
          } else {
            console.log("User document not found.");
          }
        } else {
          console.log("No user is logged in.");
        }
      } catch (error) {
        console.error("Error fetching current user data:", error);
      }
    };
  
    fetchCurrentUserName();
  }, []);
  

// Function to send notification to a player using Firebase Cloud Function
// Updated sendNotificationToPlayer to include both the recipient's name and sender's name

const sendNotificationToPlayer = async (expoPushToken, recipientName, senderName) => {
  if (expoPushToken === 'No Token') {
    Alert.alert('Notification Failed', `${recipientName} does not have a valid Expo Push Token.`);
    return;
  }

  try {
    const functions = getFunctions();
    const sendNotification = httpsCallable(functions, 'sendNotification');

    console.log("Sending notification to:", recipientName, "from:", senderName);

    const result = await sendNotification({
      expoPushToken: expoPushToken,
      title: 'Mobile Notification',
      body: `Hello ${recipientName}! You have a new message from ${senderName}!`,
    });

    Alert.alert('Notification Sent', `Notification sent to ${recipientName} from ${senderName}`);
  } catch (error) {
    console.error(`Error sending notification to ${recipientName} from ${senderName}:`, error);
    Alert.alert('Notification Error', `Failed to send notification to ${recipientName}.`);
  }
};



  // Render each game date with time
  const renderGameDate = (item, index) => (
    <Text style={styles.dateText} key={index}>
      Date: {item.date}, Time: {item.time}
    </Text>
  );

  // Render each player with name, email, expoPushToken, and pressable button to send notification
// Updated renderPlayerGameItem to pass currentUserName as the sender's name
const renderPlayerGameItem = ({ item }) => (
  <View style={styles.item}>
    <Text style={styles.text}>{item.name} - {item.email} - Token: {item.expoPushToken}</Text>
    <Pressable
      style={styles.button}
      onPress={() => sendNotificationToPlayer(item.expoPushToken, item.name, currentUserName)}
    >
      <Text style={styles.buttonText}>Send Notification</Text>
    </Pressable>
  </View>
);

  return (
    <View style={styles.container}>
      {/* Display Game Dates Above the Players List */}
      <View style={styles.gameDatesContainer}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Game Dates</Text>
        {gameDates.map((date, index) => renderGameDate(date, index))}
      </View>

      {/* Display Players List */}
      <View style={styles.playersContainer}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Players</Text>
        <FlatList
          data={playerGameData}
          renderItem={renderPlayerGameItem}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#FFFFFF',
  },
  gameDatesContainer: {
    marginBottom: 20,  // Add margin between game dates and players
  },
  dateText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  playersContainer: {
    flex: 1,
  },
  item: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    flexDirection: 'row',  // Arrange in a row
    justifyContent: 'space-between',  // Space between text and button
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    flex: 1,  // Allow the text to take available space
  },
  button: {
    backgroundColor: '#4CAF50',
    padding: 10,
    marginLeft: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default Reminder;
