//https://www.youtube.com/watch?v=HBmL_qSNx3M

//import React from 'react';
import * as React from "react";
import "./componenets/firebase";
import RootNavigation from "./navigation";
import Navigation from "./navigation/Navigation";

// notað fyrir datepicker svo það komi ekki warning...
// React-Native-Paper-Dates currently supports en/nl/de/pl/pt/ar/ko/fr/he/tr/enGB translations. 
// Ideally you would do this somewhere before react-native-paper-dates is used. For example, you might add the follow to your index.js or app.js.

import { enGB, registerTranslation } from "react-native-paper-dates";
registerTranslation("en-GB", enGB);
//registerTranslation("en-GB", enGB);

export default function App() {
  return <RootNavigation />;

  //return <Navigation />
}
