import * as Notifications from 'expo-notifications';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const db = getFirestore();

export const getAndStorePushToken = async () => {
  try {
    const { status } = await Notifications.getPermissionsAsync();
    const notificationsEnabled = status === 'granted';

    // If permission is not granted, request it
    if (!notificationsEnabled) {
      const { status: newStatus } = await Notifications.requestPermissionsAsync();
      if (newStatus !== 'granted') return; // Exit if permission is still not granted
    }

    const pushToken = (await Notifications.getExpoPushTokenAsync()).data;
    const userId = getAuth().currentUser?.uid;

    if (userId) {
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        expoPushToken: pushToken,
        notification: notificationsEnabled,  // Stores true if granted, false if not
       });
      console.log('Push token and notification preference saved in Firebase');
    }
  } catch (error) {
    console.error('Error storing push token and notification status:', error);
  }
};
