import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  ScrollView,
  Linking, 
} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";
import { Avatar } from "react-native-elements";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { database, auth } from "../componenets/firebase";
import { getAuth } from "firebase/auth";

import * as ImagePicker from "expo-image-picker";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { MaterialIcons } from "@expo/vector-icons";
import * as ImageManipulator from 'expo-image-manipulator';
import { useUser } from '../navigation/UserContext'; // Adjust the path according to your project structure





const ProfileTestStack = createNativeStackNavigator();

const Profile = () => (
  <ProfileTestStack.Navigator
    screenOptions={{
      headerShown: true,
    }}
  >
    <ProfileTestStack.Screen
      name="ProfileTestScreen"
      component={ProfileTestScreen}
      options={({ navigation }) => ({
        headerTitle: () => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => navigation.goBack()}
              style={{ marginRight: 10 }}
            >
              <MaterialIcons name="arrow-back" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>
                Go back
              </Text>
            </TouchableOpacity>
          </View>
        ),
        headerStyle: {
          backgroundColor: "#4361ee",
        },
        headerTintColor: "white",
        headerTitleStyle: {
          fontWeight: "bold",
          color: "white",
        },
        headerRight: () => (
          <View style={{ marginRight: 10 }}>
            <Avatar
              rounded
              size="medium"
              source={{
                uri: "https://i.pravatar.cc/300",
              }}
              onPress={() => navigation.openDrawer()}
              activeOpacity={0.1}
            />
            <TouchableOpacity onPress={() => navigation.openDrawer()}>
              <Text style={{ color: "red" }}> User! </Text>
            </TouchableOpacity>
          </View>
        ),
      })}
    />
  </ProfileTestStack.Navigator>
);


const ProfileTestScreen = () => {
  const navigation = useNavigation();
  const [userName, setUserName] = useState("");
  const [originalUserName, setOriginalUserName] = useState("");
  const [email, setEmail] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [editing, setEditing] = useState(false);
  const [avatarUriURL, setAvatarUriURL] = useState("");
  const [selectedImageUri, setSelectedImageUri] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { globalUserId, setGlobalUserData } = useUser(); // Access globalUserId and function to update global state


  

  const handleCancelEdit = () => {
    setUserName(originalUserName);  // Revert to the original user name
    setEmail(originalEmail);        // Revert to the original email
    setSelectedImageUri("");        // Reset the selected image URI
    setEditing(false);              // Exit editing mode
  };

  // Function to get initials
  const getInitials = (name) => {
    if (!name) return "";

    const nameParts = name.split(" ");
    let initials = nameParts[0].charAt(0).toUpperCase(); // First letter of the first name

    if (nameParts.length > 1) {
      initials += nameParts[1].charAt(0).toUpperCase(); // First letter of the last name (if available)
    }

    return initials;
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", fetchUserData);
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    const headerRightComponent = () => (
      <View style={{ alignItems: "center", marginRight: 28 }}>
        <Avatar
          rounded
          size="medium"
          source={{ uri: avatarUriURL || "https://i.pravatar.cc/300" }}
          activeOpacity={0.7}
        />
        <Text style={{ color: "white", fontSize: 14, marginTop: 0 }}>
          {userName}
        </Text>
      </View>
    );

    navigation.setOptions({
      headerRight: headerRightComponent,
    });
  }, [userName, avatarUriURL, navigation]);

  const fetchUserData = useCallback(async () => {
    const firestoreInstance = getFirestore();

    if (globalUserId) {
      const userDocRef = doc(firestoreInstance, "users", globalUserId);
      try {
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserName(userData.name);
          setEmail(userData.email);
          setAvatarUriURL(userData.avatarUri);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  }, [globalUserId]);
  


const uploadToFirebase = async (uri) => {
  console.log('Starting image upload to Firebase Storage');

  // Convert the image URI to a Blob
  const response = await fetch(uri);
  const blob = await response.blob();

  // Create a reference to Firebase Storage
  const storage = getStorage();
  const storageRef = ref(storage, `userProfile/${auth.currentUser.uid}/profile-picture`);

  // Start the upload process
  const uploadTask = uploadBytesResumable(storageRef, blob);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        console.log(`Upload is ${(snapshot.bytesTransferred / snapshot.totalBytes) * 100}% done`);
      },
      (error) => {
        console.error('Upload failed:', error);
        reject(error);
      },
      async () => {
        // Handle successful uploads
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('Image uploaded successfully, download URL:', downloadUrl);
        resolve(downloadUrl);
      }
    );
  });
};


  // Image cropping and resizing logic
  const cropImageTo1MB = async (uri) => {
    
    // Image cropping and resizing logic
   const fileInfo = await fetch(uri).then(response => response.blob());

  // Convert bytes to megabytes
  const fileSizeInMB = fileInfo.size / (1024 * 1024);
  console.log(`Original file size: ${fileSizeInMB.toFixed(2)} MB`);

  // Estimate final size based on a compression ratio and resizing
  const compressionRatio = 0.9; // Initial assumption for compression
  const estimatedFinalSizeMB = fileSizeInMB * compressionRatio * compressionRatio;
  console.log(`Estimated final size after initial compression: ${estimatedFinalSizeMB.toFixed(2)} MB`);

  // If estimated final size is still greater than 0.5 MB, apply more aggressive measures
  if (estimatedFinalSizeMB > 0.5) {
    let compressQuality = 0.9; // Start with a high quality
    let manipulatedImage = await ImageManipulator.manipulateAsync(
      uri,
      [],
      { compress: compressQuality, format: ImageManipulator.SaveFormat.JPEG }
    );
    console.log(`Compressed file size: ${(manipulatedImage.size / (1024 * 1024)).toFixed(2)} MB`);

    // Resize and continue compressing until the file is below 0.5 MB
    while (manipulatedImage.size / (1024 * 1024) > 0.5 && compressQuality > 0) {
      compressQuality -= 0.1; // Reduce quality step by step

      // Resize dimensions (reduce by 10% each iteration)
      const width = manipulatedImage.width * 0.9;
      const height = manipulatedImage.height * 0.9;

      manipulatedImage = await ImageManipulator.manipulateAsync(
        manipulatedImage.uri,
        [{ resize: { width, height } }],
        { compress: compressQuality, format: ImageManipulator.SaveFormat.JPEG }
      );
      console.log(`Resized file size: ${(manipulatedImage.size / (1024 * 1024)).toFixed(2)} MB`);
    }

    return manipulatedImage.uri;
  }

  console.log(`No need to resize, file size is under 0.5MB`);
  return uri;
};

  

const handleSaveProfileImage = async () => {
  console.log('Upload Profile Image button pressed');

  const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (!permissionResult.granted) {
    console.log('Permission to access photo library denied');
    alert("Permission to access the photo library is required!");
    return;
  }

  console.log('Permission granted to access photo library');

  let pickerResult = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    quality: 1,
  });

  if (!pickerResult.canceled) {
    console.log('Image selected by the user');
    
    const croppedUri = await cropImageTo1MB(pickerResult.assets[0].uri);
    console.log(`Cropped and resized image URI: ${croppedUri}`);
    
    setSelectedImageUri(croppedUri); // Only set the URI here, don't upload yet
  } else {
    console.log('Image selection was canceled by the user');
  }
};

const handleSaveProfile = async () => {
  if (editing) {
    setIsLoading(true);
    let updatedData = {};
    
    if (selectedImageUri) {
      const downloadUrl = await uploadToFirebase(selectedImageUri);
      setAvatarUriURL(downloadUrl);

      const userRef = doc(database, "users", auth.currentUser.uid);
      await updateDoc(userRef, { avatarUri: downloadUrl });

      updatedData.avatarUri = downloadUrl; // Prepare to update global state
    }
    
    if (userName !== originalUserName || email !== originalEmail) {
      await handleSaveUsername();
      updatedData.name = userName; // Prepare to update global state
    }

    // Update global state with the new avatar and/or name
    setGlobalUserData((prev) => ({
      ...prev,
      ...updatedData,
    }));

    setEditing(false);
    setIsLoading(false);
  }
};







  const handleSaveUsername = async () => {
    const userId = auth.currentUser.uid;
    const userRef = doc(database, "users", userId);
  
    try {
      const updateData = { name: userName, email: email };
  
      await updateDoc(userRef, updateData);
  
      // Optionally update related documents in other collections
      const mainGameRef = collection(database, "MainGame");
      const mainGameSnapshot = await getDocs(mainGameRef);
  
      for (const mainGameDoc of mainGameSnapshot.docs) {
        let gameData = mainGameDoc.data();
  
        const updatedMemberOfGame = gameData.memberOfGame.map((member) => {
          if (member.uid === userId) {
            return {
              ...member,
              name: userName,
              email: email,
              avatarUri: avatarUriURL,
            };
          }
          return member;
        });
  
        await updateDoc(doc(database, "MainGame", mainGameDoc.id), {
          memberOfGame: updatedMemberOfGame,
        });
  
        // Additionally, iterate over TheGame subcollections if there are specific player details to update
        const theGameRef = collection(mainGameDoc.ref, "TheGame");
        const theGameSnapshot = await getDocs(theGameRef);
  
        for (const theGameDoc of theGameSnapshot.docs) {
          // Here you would update specific details within each game. For example, if each game has a Players subcollection:
          const playerDocRef = doc(theGameDoc.ref, "Players", userId);
          const playerDocSnapshot = await getDoc(playerDocRef);
  
          if (playerDocSnapshot.exists()) {
            // Update the player document within TheGame subcollection
            await updateDoc(playerDocRef, updateData);
            console.log( `Updated player ${userId} in ${theGameDoc.id} within MainGame ${mainGameDoc.id}` );
          }
        }
      }
    } catch (error) {
      console.error("Error updating username:", error);
    }
  };
  
  const handleEditProfile = async () => {
    if (editing) {
      setIsLoading(true);
  
      const updateUserInfoInMainGame1 = async () => {
        const firestore = getFirestore();
        const auth = getAuth();
        const currentUserUid = auth.currentUser?.uid;
        const oldEmail = auth.currentUser?.email;
  
        if (!currentUserUid || !oldEmail) {
          console.log("User UID or email not found");
          setIsLoading(false);
          return;
        }
  
        try {
          // Update the name and email in the 'users' collection
          const userRef = doc(firestore, "users", currentUserUid);
          await updateDoc(userRef, { name: userName, email: email });
          console.log("User name and email updated successfully in users collection");
  
          const mainGameRef = collection(firestore, "MainGame");
          const querySnapshot = await getDocs(mainGameRef);
  
          for (const docSnapshot of querySnapshot.docs) {
            const gameData = docSnapshot.data();
            const docRef = doc(firestore, "MainGame", docSnapshot.id);
  
            // Check if any member of the game matches the old email
            const memberWithEmailExists = gameData.memberOfGame.some(
              (member) => member.email === oldEmail
            );
  
            if (memberWithEmailExists) {
              console.log(`Found email in MainGame document ${docSnapshot.id}:`, oldEmail);
  
              // Update 'Players' Subcollection
              const theGameRef = collection(firestore, "MainGame", docSnapshot.id, "TheGame");
              const theGameSnapshot = await getDocs(theGameRef);
  
              for (const theGameDoc of theGameSnapshot.docs) {
                const playersRef = collection(
                  firestore,
                  "MainGame",
                  docSnapshot.id,
                  "TheGame",
                  theGameDoc.id,
                  "Players"
                );
                const playersSnapshot = await getDocs(playersRef);
  
                for (const playerDoc of playersSnapshot.docs) {
                  const playerData = playerDoc.data();
                  if (playerData.email === oldEmail) {
                    const playerDocRef = doc(
                      firestore,
                      "MainGame",
                      docSnapshot.id,
                      "TheGame",
                      theGameDoc.id,
                      "Players",
                      playerDoc.id
                    );
                    await updateDoc(playerDocRef, {
                      id: currentUserUid,
                      Name: userName,
                      email: email,
                    });
                    console.log(`Updated player ${userName} in ${theGameDoc.id} within MainGame ${docSnapshot.id}`);
                  }
                }
              }
  
              // Update 'memberOfGame'
              const updatedMembers = gameData.memberOfGame.map((member) => {
                if (member.email === oldEmail) {
                  console.log(`Updating member:`, member);
                  return {
                    ...member,
                    uid: currentUserUid,
                    name: userName,
                    email: email,
                    role: "verified",
                  };
                }
                return member;
              });
  
              await updateDoc(docRef, { memberOfGame: updatedMembers });
              console.log(`Updated memberOfGame in MainGame document: ${docSnapshot.id}`);
            } else {
              console.log(`No matching email found in MainGame document: ${docSnapshot.id}`);
            }
          }
  
          // Save the new avatar URL to the 'users' collection if a new image was selected
          if (selectedImageUri) {
            const downloadUrl = await uploadToFirebase(selectedImageUri);
            setAvatarUriURL(downloadUrl); // Update the local state with the new URL
  
            // Update the avatar URL in Firestore
            await updateDoc(userRef, { avatarUri: downloadUrl });
            console.log("Avatar URL updated successfully in users collection");
          }
        } catch (error) {
          console.error("Error updating user profile:", error);
        }
      };
  
      // Update name, email, and avatar if changes are detected
      if (userName !== originalUserName || email !== originalEmail || selectedImageUri) {
        await updateUserInfoInMainGame1();
      }
  
      setEditing(false);
      setIsLoading(false);
    } else {
      // Enter editing mode
      setOriginalUserName(userName);
      setOriginalEmail(email);
      setEditing(true);
    }
  };
  


  return (
  <ScrollView contentContainerStyle={styles.container}>
    <Text style={styles.title}>My Profile</Text>
    {isLoading ? (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text style={styles.loadingText}>
          Updating, this may take a minute...
        </Text>
      </View>
    ) : (
      <>
        {editing ? (
          <>
            <Text style={styles.label}>User Name:</Text>
            <TextInput
              value={userName}
              onChangeText={setUserName}
              style={styles.input}
              placeholder="User Name"
            />
            <TouchableOpacity
              onPress={handleSaveProfileImage}
              style={styles.uploadButton}
            >
              <Text style={styles.buttonText}>Upload Profile Image</Text>
            </TouchableOpacity>
            {selectedImageUri || avatarUriURL ? (
      <Avatar
        rounded
        size="xlarge"
        source={{ uri: selectedImageUri || avatarUriURL }}
        activeOpacity={0.7}
        containerStyle={styles.avatar}
      />
    ) : (
      <View style={styles.initialsAvatar}>
        <Text style={styles.initialsText}>{getInitials(userName)}</Text>
      </View>
    )}
            <View style={styles.buttonGroup}>
              <Pressable
                onPress={handleEditProfile}
                style={({ pressed }) => [
                  styles.button,
                  styles.saveButton,
                  { opacity: pressed ? 0.7 : 1 },
                ]}
              >
                <Text style={styles.buttonText}>Save</Text>
              </Pressable>
              <Pressable
                onPress={handleCancelEdit}
                style={({ pressed }) => [
                  styles.button,
                  styles.cancelButton,
                  { opacity: pressed ? 0.7 : 1 },
                ]}
              >
                <Text style={styles.buttonText}>Cancel</Text>
              </Pressable>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.text}>{userName}</Text>
            <Text style={styles.text}>{email}</Text>
            {avatarUriURL ? (
              <Avatar
                rounded
                size="xlarge"
                source={{ uri: avatarUriURL }}
                activeOpacity={0.7}
                containerStyle={styles.avatar}
              />
            ) : (
              <View style={styles.initialsAvatar}>
                <Text style={styles.initialsText}>{getInitials(userName)}</Text>
              </View>
            )}
            <Pressable
              onPress={handleEditProfile}
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.7 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>Edit Profile</Text>
            </Pressable>

            <Pressable
              onPress={() => navigation.navigate('AccountSettings')}
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>Manage Account</Text>
            </Pressable>

            <Pressable
              onPress={() => navigation.navigate('NotificationSettings')}
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>NotificationSettings</Text>
            </Pressable>

            <Pressable
              onPress={() =>
                Linking.openURL(
                  "https://www.privacypolicies.com/live/5bd23615-8735-4c49-9ce2-5864543b187c"
                )
              }
              style={({ pressed }) => [
                styles.button,
                { backgroundColor: "#4361ee", opacity: pressed ? 0.5 : 1 },
              ]}
            >
              <Text style={styles.buttonText}>Privacy Policy</Text>
            </Pressable>
          </>
        )}
      </>
    )}
  </ScrollView>
);
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    padding: 20,
  },
  title: {
    fontSize: 28,
    marginBottom: 20,
    fontWeight: "bold",
    color: "#343a40",
  },
  label: {
    fontSize: 18,
    color: "#495057",
    marginBottom: 8,
  },
  input: {
    borderColor: "#ced4da",
    borderWidth: 1,
    borderRadius: 8,
    padding: 12,
    width: "100%",
    marginBottom: 15,
    fontSize: 16,
  },
  button: {
    marginTop: 12,
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 8,
    shadowOpacity: 0.3,
    shadowRadius: 4,
    shadowColor: "#000000",
    shadowOffset: { height: 2, width: 0 },
    elevation: 4,
  },
  buttonText: {
    fontSize: 16,
    color: "#ffffff",
    fontWeight: "600",
    textAlign: "center",
  },
  avatar: {
    marginBottom: 25,
    borderWidth: 3,
    borderColor: "#ced4da",
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: "#495057",
  },
  buttonGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 10,
  },
  saveButton: {
    backgroundColor: "#28a745",
    flex: 1,
    marginRight: 10,
  },
  cancelButton: {
    backgroundColor: "#dc3545",
    flex: 1,
  },
  uploadButton: {
    backgroundColor: "#007bff",
    marginBottom: 20,
  },
  text: {
    fontSize: 18,
    color: "#495057",
    marginBottom: 12,
  },
  initialsAvatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: "#4361ee",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  initialsText: {
    fontSize: 40,
    color: "#ffffff",
    fontWeight: "bold",
  },

});

export default Profile;
