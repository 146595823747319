import React, { useState, useEffect } from 'react';
import { StyleSheet, SafeAreaView, FlatList, Text, View } from 'react-native';
import { collection, doc, getDoc, collectionGroup, query, where, onSnapshot } from "firebase/firestore";
import { database } from "../componenets/firebase";
import moment from 'moment';

const PvpLeaderScore = ({ route }) => {
  const [dates, setDates] = useState([]);
  const { mainGameId, TheGameId } = route.params;

  const PlayerRow = ({ player, index }) => (
    <View style={styles.playerCard} key={index}>
      <Text style={styles.nameText}>{player.name}</Text>
      <Text style={styles.countText}>Pvp Score: {player.pvpScore}</Text>
    </View>
  );

  const DateRow = ({ date, games }) => (
    <View>
      {games.map((game, index) => (
        <View key={index}>
          <Text style={styles.dateHeader}>
            Game Date: {moment(date, 'YYYY-MM-DD').format('MMMM Do YYYY')} - Game #{game.gameIndex}
          </Text>
          {game.players.map((player, playerIndex) => (
            <PlayerRow player={player} index={playerIndex} key={playerIndex} />
          ))}
        </View>
      ))}
    </View>
  );

  useEffect(() => {
    const fetchData = async () => {
      const mainGameRef = doc(database, 'MainGame', mainGameId);
      const mainGameSnapshot = await getDoc(mainGameRef);
      const mainGameData = mainGameSnapshot.data();
      console.log('mainGameData:', mainGameData);

      const gameIndex = mainGameData?.gameIndex || '';

      const fetchPlayersQuery = query(
        collectionGroup(database, 'Players'),
        where('mainGameId', '==', mainGameId)
      );

      const unsubscribe = onSnapshot(fetchPlayersQuery, (querySnapshot) => {
        let playerData = querySnapshot.docs.map((doc) => {
          const player = doc.data();
          player.gameDate = player.gameDate || null;
          console.log(`Player: ${player.name}, Game Date: ${player.gameDate}, Game Index: ${player.gameIndex}`);
          return player;
        });
    
        const dates = playerData.reduce((acc, player) => {
          const { gameDate, gameIndex } = player;
          const dateKey = moment(gameDate).format('YYYY-MM-DD');
          const existingDate = acc.find((dateObj) => dateObj.date === dateKey);
          if (existingDate) {
            const existingGame = existingDate.games.find((game) => game.gameIndex === gameIndex);
            if (existingGame) {
              existingGame.players.push(player);
              existingGame.players.sort((a, b) => {
                if (a.pvpScore !== b.pvpScore) {
                  return b.pvpScore - a.pvpScore; // Sort by Pvp score in descending order
                } else {
                  return a.name.localeCompare(b.name); // Sort by name in ascending order
                }
              });
            } else {
              existingDate.games.push({ gameIndex, players: [player] });
            }
          } else {
            acc.push({ date: dateKey, games: [{ gameIndex, players: [player] }] });
          }
          return acc;
        }, []);
    
        dates.sort((a, b) => moment(b.date, 'YYYY-MM-DD').diff(moment(a.date, 'YYYY-MM-DD')));
    
        setDates(dates);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchData();
  }, [mainGameId]);

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={dates}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => (
          <DateRow date={item.date} games={item.games} />
        )}
      />
    </SafeAreaView>
  );
};

export default PvpLeaderScore;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#f4f4f4',
  },
  playerCard: {
    backgroundColor: '#fff',
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
  dateHeader: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#7a7a7a',
  },
  nameText: {
    fontSize: 18,
    color: '#000',
    marginBottom: 5,
  },
  countText: {
    fontSize: 16,
    color: '#000',
  },
});
