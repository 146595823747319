// Import necessary components and functions
import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, StyleSheet, Button, Alert, Platform, Pressable } from 'react-native';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { database } from '../componenets/firebase';

const GameList = () => {
    const [games, setGames] = useState([]);

    useEffect(() => {
        fetchGames();
    }, []);

    // Function to fetch games from Firestore, including players data
    const fetchGames = async () => {
        const gamesCollectionRef = collection(database, "MainGame");
        const gameSnapshot = await getDocs(gamesCollectionRef);
        const gamesData = gameSnapshot.docs.map(gameDoc => {
            const gameData = gameDoc.data();
            const gameId = gameDoc.id;

            // Extract players data from the 'memberOfGame' array in each game document
            const players = gameData.memberOfGame ? gameData.memberOfGame.map(player => ({
                name: player.name,
                role: player.role,
                isOwner: player.isOwner,
                isAdmin: player.isAdmin,

                 // Assuming 'name' is a field within each player object
            })) : [];

            return {
                id: gameId,
                ...gameData,
                players
            };
        });
        setGames(gamesData);
    };

    // Function to handle game deletion with confirmation
    const deleteGame = (gameId) => {
        if (Platform.OS === "web") {
            if (window.confirm("Are you sure you want to delete this game? This action cannot be undone.")) {
                performDeletion(gameId);
            }
        } else {
            Alert.alert(
                "Confirm Deletion",
                "Are you sure you want to delete this game? This action cannot be undone.",
                [
                    { text: "Cancel", onPress: () => console.log("Deletion cancelled"), style: "cancel" },
                    { text: "Delete", onPress: () => performDeletion(gameId), style: "destructive" }
                ],
                { cancelable: false }
            );
        }
    };

// Function to perform the actual deletion of the game and its subcollection "TheGame"
const performDeletion = async (gameId) => {
    const gameDocRef = doc(database, "MainGame", gameId);
    // Get a reference to the 'TheGame' subcollection
    const theGameCollectionRef = collection(gameDocRef, "TheGame");
    
    try {
        const gameSnapshot = await getDocs(theGameCollectionRef);
        // Iterate over each document in the 'TheGame' subcollection
        for (const gameDoc of gameSnapshot.docs) {
            // Get and delete each 'Messages' subcollection document
            const messagesCollectionRef = collection(gameDoc.ref, "Messages");
            const messagesSnapshot = await getDocs(messagesCollectionRef);
            const messageDeletions = messagesSnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(messageDeletions);

            // Get and delete each 'Players' subcollection document
            const playersCollectionRef = collection(gameDoc.ref, "Players");
            const playersSnapshot = await getDocs(playersCollectionRef);
            const playerDeletions = playersSnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(playerDeletions);

            // Once all subcollections are cleared, delete the gameDoc itself
            await deleteDoc(gameDoc.ref);
        }

        // After clearing all subcollections under 'TheGame', delete the game document itself
        await deleteDoc(gameDocRef);
        // Update the local state to reflect the deletion
        setGames(games.filter(game => game.id !== gameId));
     //   console.log("Game and all associated data deleted successfully");
    } catch (error) {
        console.error("Error while deleting game and subcollections:", error);
    }
};



return (
    <View style={styles.container}>
        <View>
        <Text style={styles.totalGames}>Total Games: {games.length}</Text> 
        </View>
        <FlatList
            data={games}
            keyExtractor={item => item.id}
            renderItem={({ item, index }) => (
                <View style={styles.gameItem}>
                    <Text style={styles.gameInfo}>{index + 1}. Game Name: {item.name} - Game Id: {item.id}</Text>
                    {item.players.length > 0 ? item.players.map((player, idx) => (
                        <Text key={idx} style={styles.playerInfo}>
                            Player Name: {player.name} - Admin: {player.isAdmin ? 'Yes' : 'No'} - Owner: {player.isOwner ? 'Yes' : 'No'} - Role: {player.role}
                        </Text>
                    )) : <Text style={styles.playerInfo}>No players found for this game.</Text>}
                    <Pressable style={styles.button} onPress={() => deleteGame(item.id)}>
                        <Text style={styles.text}>Delete Game</Text>
                    </Pressable>
                </View>
            )}
        />
    </View>
);
};


// Styles remain the same as previously enhanced
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#ffffff',
    },
    gameItem: {
        padding: 15,
        marginVertical: 10,
        backgroundColor: '#e8eaf6',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#c5cae9',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    gameInfo: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#3f51b5',
        marginBottom: 5,
    },
    playerInfo: {
        fontSize: 16,
        marginLeft: 20,
        color: '#3949ab',
    },
    button: {
        backgroundColor: '#ff1744',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
      },
      text: {
        color: 'white',
        fontSize: 16,
      },
      totalGames: {  // New style for the total games header
        fontSize: 20,
        fontWeight: 'bold',
        color: '#3f51b5',
        textAlign: 'center',
        marginVertical: 10,
        textDecorationLine: 'underline',
    },

});

export default GameList;
