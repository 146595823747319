import React, { useState, useEffect } from 'react';
import { StyleSheet, SafeAreaView, FlatList, Text, View, Button } from 'react-native';
import { collectionGroup, query, where, onSnapshot } from "firebase/firestore";
import { database } from "../componenets/firebase";

const PvpLeaderBoard = ({ route, navigation }) => {
  const [playerData, setPlayerData] = useState({});
  const { mainGameId } = route.params;

  useEffect(() => {
    const fetchPlayersQuery = query(
      collectionGroup(database, 'Players'),
      where('mainGameId', '==', mainGameId)
    );

    const unsubscribe = onSnapshot(fetchPlayersQuery, (querySnapshot) => {
      const data = {};

      querySnapshot.docs.forEach((doc) => {
        const player = doc.data();
        const playerName = player.name;

        if (!data[playerName]) {
          data[playerName] = { totalScore: 0, gamesPlayed: 0 };
        }
        data[playerName].totalScore += player.pvpScore;
        data[playerName].gamesPlayed += 1;
      });

      setPlayerData(data);
    });

    return () => {
      unsubscribe();
    };
  }, [mainGameId]);

  const navigateToPvpLeaderScore = () => {
    navigation.navigate('PvpLeaderScore', { mainGameId: mainGameId });
  };

  return (
    <SafeAreaView style={styles.container}>
      <Button title="Go to Pvp Leader Score" onPress={navigateToPvpLeaderScore} />
      <FlatList
        data={Object.entries(playerData)}
        keyExtractor={([name,]) => name}
        renderItem={({ item: [name, { totalScore, gamesPlayed }] }) => (
          <View style={styles.playerCard}>
            <Text style={styles.nameText}>{name}</Text>
            <Text style={styles.scoreText}>Total PvP Score: {totalScore}</Text>
            <Text style={styles.gameCountText}>Games Played: {gamesPlayed}</Text>
          </View>
        )}
      />
    </SafeAreaView>
  );
};

export default PvpLeaderBoard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#f4f4f4',
  },
  playerCard: {
    backgroundColor: '#fff',
    marginBottom: 10,
    padding: 20,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
  nameText: {
    fontSize: 18,
    color: '#000',
    marginBottom: 5,
  },
  scoreText: {
    fontSize: 16,
    color: '#000',
    marginBottom: 5,
  },
  gameCountText: {
    fontSize: 16,
    color: '#000',
  },
});
