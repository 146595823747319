import { View, Text, Button } from 'react-native';
import React from 'react';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import { USER_ID, SERVICE_ID, TEMPLATE_ID } from '@env'

init(USER_ID);

const TabLeaderBoard = () => {
  const sendEmail = async () => {
    const templateParams = {
      to_email: 'dondanni@hotmail.com', 
      from_name: 'dondanni@hotmail.com',
      subject: 'Hello from EmailJS!',
      message: 'This is a test email sent using EmailJS!',
    };

    try {
      let result = await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        templateParams
      );

      console.log('Email sent successfully', result.text);
    } catch (error) {
      console.error('Error sending email: ', error.text);
    }
  };

  return (
    <View>
      <Text>Email</Text>
      <Button title="Send Email" onPress={sendEmail} />
    </View>
  );
};

export default TabLeaderBoard;
