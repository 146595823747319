import React, { useEffect, useState, useCallback, useMemo } from "react";
import { View, Text, ScrollView, StyleSheet } from "react-native";
import { collectionGroup, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import { database } from "../componenets/firebase";
import moment from "moment";
import { Avatar } from "react-native-elements";

const ScoreCard = ({ navigation, route }) => {
  const { mainGameId, theGameId } = route.params;
  const [leaderboardData, setLeaderboardData] = useState([]);

  const uniqueDates = useMemo(() => {
    return [...new Set(leaderboardData.map(item => item.date + " + Game " + item.gameIndex))];
  }, [leaderboardData]);

  const uniquePlayers = useMemo(() => {
    return [...new Set(leaderboardData.map(item => item.Name))];
  }, [leaderboardData]);

  const fetchAvatarUri = useCallback(async (userId) => {
    try {
      const userDoc = doc(database, "users", userId);
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      return userData?.avatarUri;
    } catch (error) {
      console.error("Error fetching avatar:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    const leaderboardQuery = query(
      collectionGroup(database, "Players"),
      where("mainGameId", "==", mainGameId)
    );

    const unsubscribe = onSnapshot(leaderboardQuery, async (querySnapshot) => {
      try {
        const leaderboard = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const avatarUri = await fetchAvatarUri(data.userId);
            return {
              ...data,
              date: moment(data.date).format("D.MMM 'YY"),
              avatarUri
            };
          })
        );
        setLeaderboardData(leaderboard);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    });

    return () => unsubscribe();
  }, [mainGameId, fetchAvatarUri]);

  const playerAvatarMapping = useMemo(() => {
    return leaderboardData.reduce((acc, playerData) => {
      acc[playerData.Name] = playerData.avatarUri;
      return acc;
    }, {});
  }, [leaderboardData]);

  return (
    <ScrollView horizontal>
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.firstColumn}></Text>
          {uniquePlayers.map((player, index) => (
            <View key={index} style={styles.column}>
              <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                {playerAvatarMapping[player] ? (
                  <Avatar
                    source={{ uri: playerAvatarMapping[player] }}
                    size="small"
                    rounded
                    containerStyle={styles.avatar}
                  />
                ) : (
                  <Avatar
                    size="small"
                    rounded
                    title={String(player).charAt(0) + String(player).charAt(1)}
                    overlayContainerStyle={{ backgroundColor: "#bcbec1" }}
                    containerStyle={styles.avatar}
                  />
                )}
                <Text style={styles.header}>{player}</Text>
              </View>
            </View>
          ))}
        </View>
        {uniqueDates.map((date, index) => (
          <View key={index} style={styles.row}>
            <View style={styles.firstColumn}>
              <Text style={styles.header}>{date}</Text>
              <Text style={styles.cell}>{"Win Points"}</Text>
               <Text style={styles.cell}>{"Draw Points"}</Text>
              <Text style={styles.cell}>{"Lose Points"}</Text>
              <Text style={styles.cell}>{"Attendance Points"}</Text>
              <Text style={styles.cell}>{"Ten Goal Points"}</Text>
              <Text style={styles.cell}>{"Total points"}</Text>
            </View>
            {uniquePlayers.map((player, playerIndex) => {
              const playerData = leaderboardData.find(
                (item) => item.Name === player && `${item.date} + Game ${item.gameIndex}` === date
              );
              return (
                <View key={playerIndex} style={styles.column}>
                  <Text>  </Text>
                  <Text style={styles.cell}>{playerData?.winPoints || "-"}</Text>
                  <Text style={styles.cell}>{playerData?.drawPoints || "-"}</Text>
                  <Text style={styles.cell}>{playerData?.losePoints || "-"}</Text>
                  <Text style={styles.cell}>{playerData?.attendancePoints || "-"}</Text>
                  <Text style={styles.cell}>{playerData?.teenGoalPoint || "-"}</Text>
                  <Text style={styles.cell}>{playerData?.totalPoints || "-"}</Text>
                </View>
              );
            })}
          </View>
        ))}
      </View>
    </ScrollView>
  );
  
};




 


export default ScoreCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  
  row: {
    //width: 200,
    borderWidth: 1,
    borderColor: 'blue',

    flexDirection: 'row',
    //justifyContent: 'flex-start',
    marginBottom: 10,
  },

  column: {
    borderWidth: 1,
    borderColor: 'red', 
    width: 200,
    alignItems: 'center',
  },
  firstColumn: {
    width: 200,
    borderWidth: 1,
    //alignItems: 'center',
    //minWidth: 150,
  },
  header: {
    width: 200,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  cell: {
    width: 200,
    textAlign: 'center',
    marginBottom: 5,
  },
});



