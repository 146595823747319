import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  collection,
  query,
  where,
  arrayRemove,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";

import { Avatar } from "react-native-elements";
import useAvatarUri from "./Avatar";
import { database } from "../componenets/firebase";

//  const { mainGameId } = route.params; 

// Separate PlayerRow component
const PlayerRow = ({ player, game, TheGameId,GameDate,GameIndex }) => {
  //const {gameDate} = route.params;
  console.log("GameIndex-not-working: ", GameIndex);
  console.log("GameDateTest: ", GameDate);

  const [beerCount, setBeerCount] = useState(player?.beerCount || 0);



  useEffect(() => {
    setBeerCount(player?.beerCount || 0);
  }, [player?.beerCount]);

  const incrementBeerCount = async () => {
    const newBeerCount = beerCount + 1;
    const playerDocRef = doc(database, "MainGame", game.id, "TheGame", TheGameId, "Players", player.uid);
  
    const docSnapshot = await getDoc(playerDocRef);
    if (docSnapshot.exists()) {
      // Document exists, just update beerCount
      await updateDoc(playerDocRef, { beerCount: newBeerCount });
    } else {
      // Document does not exist, create it with all fields
      await setDoc(
        playerDocRef,
        {
          beerCount: newBeerCount,
          uid: player.uid,
          mainGameId: game.id,
          name: player.name,
          gameDate: GameDate,
          gameIndex: GameIndex,
        },
        { merge: true }
      );
    }
  
    // Update local state
    setBeerCount(newBeerCount);
  };
  

const decrementBeerCount = async () => {
    // Only decrement the count if it's greater than 0
    if (beerCount > 0) {
        const newBeerCount = beerCount - 1;
        const playerDocRef = doc(database, "MainGame", game.id, "TheGame", TheGameId, "Players", player.uid);

        // Use updateDoc to decrement beerCount
        await updateDoc(playerDocRef, { beerCount: newBeerCount });

        // Update local state
        setBeerCount(newBeerCount);
    }
};

  
  

  useEffect(() => {
    const playerDocRef = doc(
      database,
      "MainGame",
      game.id,
      "TheGame",
      TheGameId,
      "Players",
      player.uid
    );
  
    const unsubscribe = onSnapshot(playerDocRef, (docSnapshot) => {
      const data = docSnapshot.data();
      if (data && data.beerCount) {
        setBeerCount(data.beerCount);
      }
    });
  
    return () => unsubscribe();
  }, [player.uid]);
  
  

  // Render logic for a single player row
 // In your PlayerRow component
// ...
return ( 
  
  <View style={styles.playerRow}>




    <View style={styles.playerInfo}>
      {player.avatarUri ? (
        <Avatar
          size="small"
          rounded
          source={{ uri: player.avatarUri }}
        />
      ) : (
        <Avatar
          size="small"
          rounded
          title={player.name.slice(0, 2).toUpperCase()}
          overlayContainerStyle={{ backgroundColor: "#bcbec1" }}
        />
      )}
      <View style={styles.nameAndBeer}>
        <Text style={styles.playerName}>{player.name}</Text>
        <Text style={styles.beerCount}>
          Beers: {beerCount}
        </Text>
      </View>
    </View>
    <View style={styles.buttonsContainer}>
      <TouchableOpacity
        style={styles.button}
        onPress={incrementBeerCount}
      >
        <Text style={styles.buttonText}>+</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.button}
        onPress={decrementBeerCount}
      >
        <Text style={styles.buttonText}>-</Text>
      </TouchableOpacity>
    </View>
  </View>
  
);


};





const BeerCounter = ({ route,navigation }) => {
  const [mainGame, setMainGame] = useState(route.params.mainGameId);
  console.log("mainGame:", mainGame);
  const [TheGameId, setTheGameId] = useState(route.params.TheGameId);
  const [GameDate, setGameDate] = useState(route.params.GameDate);
  console.log("GameDateXXX:", GameDate);
  const [GameIndex, setGameIndex] = useState(route.params.GameIndex);
  console.log("GameIndex-Test:", GameIndex);
  const [games, setGames] = useState([]);
  const fetchAvatarUri = useAvatarUri();

  useEffect(() => {
    const colRef = collection(database, "MainGame");
    const q = query(colRef, where("MainGameId", "==", mainGame));

    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const fetchedData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const gameData = doc.data();
            console.log("game.memberOfGame:", gameData.memberOfGame); // Log the members

            const memberOfGame = await Promise.all(
              gameData.memberOfGame.map(async (player) => {
                const avatarUri = await fetchAvatarUri(player.uid);
                return { ...player, avatarUri };
              })
            );

            return { ...gameData, memberOfGame, id: doc.id };
          })
        );

        setGames(fetchedData);
      },
      (error) => {
        console.error("Firebase query error:", error);
      }
    );

    return () => unsubscribe();
  }, [mainGame]);

  return (
    <SafeAreaView style={styles.container}>

    <TouchableOpacity
  style={styles.button}
  onPress={() => {
    console.log("Navigating to BeerCountLeaderboard with TheGameId:", TheGameId);
    navigation.navigate("BeerCountLeaderboard", {
      mainGameId: mainGame,
      TheGameId: TheGameId,
    });
  }}
>
  <Text style={styles.buttonText}>Beer Leaderboard (press button) </Text>
</TouchableOpacity>

 
<FlatList
    data={games}
    keyExtractor={(item) => item.id}
    renderItem={({ item: game }) => {
      console.log("gameDateBut", game.date);  // This logs the game date for each game.
      return (
        <FlatList
          data={game.memberOfGame}
          keyExtractor={(player, index) => player.uid + index.toString()}
          renderItem={({ item: player }) => (
            player && (
              <PlayerRow player={player} game={game} TheGameId={TheGameId} GameDate={GameDate} GameIndex={GameIndex}  />
            )
          )}
        />
      );
    }}
/>

    </SafeAreaView>
  );
  
};

export default BeerCounter;

// Your styles go here


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: "#f4f4f4", // a light grey background
  },
  gameName: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#fd5c63", // a vibrant pink color
  },
  playerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    padding: 15,
    backgroundColor: "#fff",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // for android
  },
  playerInfo: {
    flexDirection: "row",
    alignItems: "center",
  },
  playerName: {
    fontSize: 16,
    color: "#323232", // a dark grey color
    marginRight: 10,
  },
  beerCount: {
    fontSize: 16,
    color: "#0000FF", // a standard blue color
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  button: {
    backgroundColor: "#fd5c63",
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
  },
  buttonText: {
    fontSize: 20,
    color: "#fff",
  },
});
