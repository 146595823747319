import React, { useEffect, useState, useCallback, useMemo } from "react";
import { View, Text, StyleSheet, ScrollView, Platform } from "react-native";
import { collectionGroup, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import { database } from "../componenets/firebase";
import moment from "moment";
import { Avatar } from "react-native-elements";
import RNPickerSelect from 'react-native-picker-select';

const PointChart = ({ navigation, route }) => {
  const { mainGameId } = route.params;
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  useEffect(() => {
    const leaderboardQuery = query(collectionGroup(database, "Players"), where("mainGameId", "==", mainGameId));
    const unsubscribe = onSnapshot(leaderboardQuery, async (querySnapshot) => {
      const leaderboard = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const avatarUri = await fetchAvatarUri(data.userId);
          return {
            ...data,
            date: moment(data.date).format("D.MMM 'YY"),
            avatarUri
          };
        })
      );
      setLeaderboardData(leaderboard);
    });

    return () => unsubscribe();
  }, [mainGameId]);

  const fetchAvatarUri = useCallback(async (userId) => {
    const userDoc = doc(database, "users", userId);
    const userSnapshot = await getDoc(userDoc);
    return userSnapshot.data()?.avatarUri;
  }, []);

  const uniqueDates = useMemo(() => [...new Set(leaderboardData.map(item => `${item.date} + Game ${item.gameIndex}`))], [leaderboardData]);
  const uniquePlayers = useMemo(() => [...new Set(leaderboardData.map(item => item.Name))], [leaderboardData]);

  uniqueDates.sort((a, b) => {
    const [aDateStr, aGame] = a.split(' + Game ');
    const [bDateStr, bGame] = b.split(' + Game ');
    const aDate = new Date(aDateStr);
    const bDate = new Date(bDateStr);
    return bDate - aDate || bGame - aGame;
  });

  const playerAvatarMapping = useMemo(() => leaderboardData.reduce((acc, playerData) => {
    acc[playerData.Name] = playerData.avatarUri;
    return acc;
  }, {}), [leaderboardData]);

  return (
    <ScrollView>
      <View style={styles.container}>
        {/* <Text>Select player: </Text> */}
        <View style={{ marginVertical: 10 }}>
          {Platform.OS === 'web' ? (
            <select
              onChange={(e) => setSelectedPlayer(e.target.value)}
              style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
            >
              <option value="">Select a player</option>
              {uniquePlayers.map((player, index) => (
                <option key={index} value={player}>{player}</option>
              ))}
            </select>
          ) : (
            <RNPickerSelect
              onValueChange={(value) => setSelectedPlayer(value)}
              items={uniquePlayers.map(player => ({ label: player, value: player }))}
              placeholder={{ label: "Select a player", value: null }}
            />
          )}
        </View>
        <View style={styles.row}>
          {/* Placeholder for date column */}
          <View style={styles.firstColumn} />
          {uniquePlayers.filter(player => selectedPlayer === '' || player === selectedPlayer).map((player, index) => (
            <View key={index} style={styles.column}>
              <Text style={styles.header}>{player}</Text>
            </View>
          ))}
        </View>
        {uniqueDates.map((date, dateIndex) => (
  <View key={dateIndex} style={styles.row}>
   <View style={styles.firstColumn}>
  <Text style={styles.header}>{date.split(' + ')[0]}</Text>
  <Text style={styles.firstcell} >Win Points</Text>
  <Text style={styles.firstcell} >Draw Points</Text>
  <Text style={styles.firstcell}  >Lose Points</Text>
  <Text style={styles.firstcell}  >Attendance Points</Text>
  <Text style={styles.firstcell}  >Ten Goal Point</Text>
  <Text style={styles.firstcellTotal}  >Total Points</Text>
</View>

    {uniquePlayers.filter(player => selectedPlayer === '' || player === selectedPlayer).map((player, playerIndex) => {
      const playerData = leaderboardData.find(
        item => item.Name === player && `${item.date} + Game ${item.gameIndex}` === date
      );
      return (
        <View key={playerIndex} style={styles.column}>
          <Text style={styles.cell}>  </Text>
          <Text style={styles.cell}>{playerData?.winPoints || "-"}</Text>
          <Text style={styles.cell}>{playerData?.drawPoints || "-"}</Text>
          <Text style={styles.cell}>{playerData?.losePoints || "-"}</Text>
          <Text style={styles.cell}>{playerData?.AttendancePoints || "-"}</Text>
          <Text style={styles.cell}>{playerData?.TeenGoalPoints || "-"}</Text>
          <Text style={styles.cellTotal}>{playerData?.totalPoints || "-"}</Text>
        </View>
      );
    })}
  </View>
))}

      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  column: {
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'red',
    width: 100, // Adjust the width as needed
  },
  firstColumn: {
    width: 150, // Adjust the width as needed
    borderWidth: 1,
    borderColor: 'blue',
    alignItems: 'center', // Center align the text
  },
  header: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  cell: {
    textAlign: 'center',
    marginBottom: 5,
  },

  cellTotal: {
    textAlign: 'center',
    marginBottom: 5,
    fontWeight: "bold"
  },

  firstcell: {
    textAlign: 'center',
    marginBottom: 5,
    width: 150, // Match the firstColumn width to prevent wrapping
  },

  firstcellTotal: {
    textAlign: 'center',
    marginBottom: 5,
    width: 150, // Match the firstColumn width to prevent wrapping
    fontWeight: "bold"
  },

  

  


});

export default PointChart;