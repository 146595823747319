// Leaderboard with Date Selection Integration

import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { collectionGroup, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import { database } from "../componenets/firebase"; // Correct the path if necessary
import moment from "moment";
import { MaterialCommunityIcons } from '@expo/vector-icons';

// Function to get the initials for players' names
const getInitials = (name) => {
  const initials = name
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toUpperCase();
  return initials.slice(0, 2); // Only return the first two initials
};

const Leaderboard = ({ route }) => {
  const { mainGameId } = route.params;
  const [dates, setDates] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [rowBackgroundColor, setRowBackgroundColor] = useState("grey");
  const [gameName, setGameName] = useState("");
  const [selectedDate, setSelectedDate] = useState(null); // To track the selected date

  // Fetch the game name
  const fetchGameName = async () => {
    try {
      const gameRef = doc(database, "MainGame", mainGameId);
      const gameSnapshot = await getDoc(gameRef);

      if (gameSnapshot.exists()) {
        const gameData = gameSnapshot.data();
        setGameName(gameData.name);
      }
    } catch (error) {
      console.error("Error fetching game name:", error);
    }
  };

  const fetchAndProcessData = useCallback(async () => {
    const playersQuery = query(
      collectionGroup(database, "Players"),
      where("mainGameId", "==", mainGameId)
    );

    const unsubscribe = onSnapshot(playersQuery, (querySnapshot) => {
      let games = {};
      let players = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const gameDateFormatted = moment(data.gamedate).format("YYYY-MM-DD");
        const gameKey = `${gameDateFormatted}-${data.gameIndex}`;
        const playerKey = `${data.Name}-${data.userId}`;

        if (data.scoreHome != null && data.scoreAway != null) {
          games[gameKey] = {
            date: gameDateFormatted,
            index: data.gameIndex,
            scoreHome: data.scoreHome,
            scoreAway: data.scoreAway
          };
        }

        if (!players[playerKey]) {
          players[playerKey] = {
            Name: data.Name,
            email: data.email || '',
            userId: data.userId || '',
            games: {},
            totalPoints: 0,
            totalGF: 0,
            totalGA: 0,
            totalGD: 0
          };
        }

        if (data.totalPoints != null) {
          players[playerKey].games[gameKey] = data.totalPoints;
          players[playerKey].totalPoints += data.totalPoints;
          if (data.team === "Home") {
            players[playerKey].totalGF += data.scoreHome !== null ? data.scoreHome : 0;
            players[playerKey].totalGA += data.scoreAway !== null ? data.scoreAway : 0;
          } else {
            players[playerKey].totalGF += data.scoreAway !== null ? data.scoreAway : 0;
            players[playerKey].totalGA += data.scoreHome !== null ? data.scoreHome : 0;
          }
          players[playerKey].totalGD = players[playerKey].totalGF - players[playerKey].totalGA;
        }
      });

      const gamesArray = Object.values(games).sort((a, b) => {
        return moment(a.date).diff(moment(b.date)) || a.index - b.index;
      });
      setDates(gamesArray);

      const playersArray = Object.values(players).sort((a, b) => {
        if (b.totalPoints !== a.totalPoints) return b.totalPoints - a.totalPoints;
        if (b.totalGD !== a.totalGD) return b.totalGD - a.totalGD;
        if (b.totalGF !== a.totalGF) return b.totalGF - a.totalGF;
        return a.Name.localeCompare(b.Name);
      });

      let placeNumber = 1;
      for (let i = 0; i < playersArray.length; i++) {
        if (
          i > 0 &&
          playersArray[i].totalPoints === playersArray[i - 1].totalPoints &&
          playersArray[i].totalGD === playersArray[i - 1].totalGD &&
          playersArray[i].totalGF === playersArray[i - 1].totalGF
        ) {
          playersArray[i].place = "-";
        } else {
          playersArray[i].place = placeNumber;
        }
        placeNumber++;
      }

      setPlayersData(playersArray);
    });

    return () => unsubscribe();
  }, [mainGameId]);

  useEffect(() => {
    fetchGameName();
    fetchAndProcessData();
  }, [mainGameId, fetchAndProcessData]);

  // Function to render date buttons
  const renderDateButton = (date, index) => (
    <TouchableOpacity
      key={index}
      style={[styles.dateButton, selectedDate === date ? styles.selectedButton : null]}
      onPress={() => setSelectedDate(date)}
    >
      <Text style={styles.dateText}>{moment(date.date).format("D.MMM'YY")}</Text>
      {dates.filter(d => d.date === date.date).length > 1 && <Text>Game {date.index}</Text>}
    </TouchableOpacity>
  );
  

  // Function to get the header background color based on the current color scheme
const getHeaderBackgroundColor = () => {
  const scheme = colorSchemes[rowBackgroundColor]; // Use the state value as a key
  return scheme ? scheme.header : colorSchemes.grey.header; // Default to grey if not found
};


// Function to get the background color for rows based on the index
const getRowBackgroundColor = (index) => {
  const scheme = colorSchemes[rowBackgroundColor]; // Direct lookup by state value
  if (!scheme) {
    return { backgroundColor: colorSchemes.grey.main }; // Default to grey if scheme not found
  }

  // Determine the background color based on the row index (alternate colors for even/odd rows)
  return {
    backgroundColor: index % 2 === 0 ? scheme.main : scheme.alternate,
  };
};


// Function to render rank with special icons for the top 3 players
const renderRank = (index) => {
  switch (index) {
    case 0:
      return (
        <View style={styles.iconWithText}>
          <MaterialCommunityIcons name="medal" size={20} color="#FFD700" />
          <Text style={styles.iconText}>1</Text>
        </View>
      );
    case 1:
      return (
        <View style={styles.iconWithText}>
          <MaterialCommunityIcons name="medal" size={20} color="#C0C0C0" />
          <Text style={styles.iconText}>2</Text>
        </View>
      );
    case 2:
      return (
        <View style={styles.iconWithText}>
          <MaterialCommunityIcons name="medal" size={20} color="#CD7F32" />
          <Text style={styles.iconText}>3</Text>
        </View>
      );
    default:
      return <Text style={styles.rankText}>{index + 1}</Text>; // "+1" because index is 0-based
  }
};




  return (
    <ScrollView horizontal={true}>
      <View style={{ flexDirection: "column" }}>
        <View style={{ flexDirection: "row", padding: 10 }}>
          {dates.map((date, index) => renderDateButton(date, index))}
        </View>

        <Text style={styles.title}>Leaderboard for {gameName} </Text>

        <FlatList
          data={playersData}
          keyExtractor={(item) => item.userId}
          ListHeaderComponent={() => (
            <View>
              <View style={[styles.row, { backgroundColor: getHeaderBackgroundColor() }]}>
                <Text style={styles.placeCell}>#</Text>
                <Text style={styles.nameCell}>Name</Text>
                <Text style={styles.headerCell}>Points</Text>
                <Text style={styles.headerCell}>GF</Text>
                <Text style={styles.headerCell}>GA</Text>
                <Text style={styles.headerCell}>GD</Text>
                {dates.map(({ date, index }, arrayIndex) => (
                  <View key={`date-${index}-${arrayIndex}`} style={styles.headerCell}>
                    <Text>{moment(date).format("D.MMM'YY")}</Text>
                    {dates.filter(d => d.date === date).length > 1 && <Text>{`Game ${index}`}</Text>}
                  </View>
                ))}
              </View>

              <View style={[styles.row, { backgroundColor: colorSchemes[rowBackgroundColor].header }]}>
                <View style={styles.placeCell}></View>
                <View style={styles.nameCell}></View>
                <View style={styles.headerCell}></View>
                <View style={styles.headerCell}></View>
                <View style={styles.headerCell}></View>
                <View style={styles.headerCell}></View>
                {dates.map(({ date, index, scoreHome, scoreAway }, arrayIndex) => {
                  const displayScoreHome = scoreHome != null ? `H: ${scoreHome}` : "";
                  const displayScoreAway = scoreAway != null ? `A: ${scoreAway}` : "";
                  const displayScore = [displayScoreHome, displayScoreAway].filter(Boolean).join(" - ");
                  return (
                    <View key={`score-${index}-${arrayIndex}`} style={styles.headerCell}>
                      <Text>{displayScore}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          )}
          renderItem={({ item, index }) => (
            <View style={[styles.row, getRowBackgroundColor(index)]}>
              <View style={styles.rankCell}>{renderRank(index)}</View>
              <View style={styles.nameEmailContainer}>
                <View style={styles.initialsContainer}>
                  <Text style={styles.initialsText}>{getInitials(item.Name)}</Text>
                </View>
                <Text style={styles.nameCell} numberOfLines={1} ellipsizeMode="tail">
                  {item.Name}
                </Text>
              </View>
              <Text style={styles.pointsCell}>{item.totalPoints}</Text>
              <Text style={styles.pointsCell}>{item.totalGF}</Text>
              <Text style={styles.pointsCell}>{item.totalGA}</Text>
              <Text style={styles.pointsCell}>{item.totalGD}</Text>
              {dates.map((game, gameIndex) => {
                const gameKey = `${game.date}-${game.index}`;
                const gamePoints = item.games[gameKey] !== undefined ? item.games[gameKey] : "-";
                return (
                  <Text key={`${item.Name}-game-${gameIndex}`} style={[styles.pointsCell, { textAlign: 'center' }]}>
                    {gamePoints}
                  </Text>
                );
              })}
            </View>
          )}
          ListFooterComponent={<View style={{ marginBottom: 20 }} />}
        />
      </View>
    </ScrollView>
  );
};

// Styles and color schemes
const colorSchemes = {
  grey: {
    main: "#f2f2f2",
    alternate: "#d9d9d9",
    header: "#cccccc",
  },
  blue: {
    main: "#add8e6",
    alternate: "#89CFF0",
    header: "#99ccff",
  },
  pink: {
    main: "#F883B0",
    alternate: "#FA9AB7",
    header: "#E5288C",
  },
  dark: {
    main: "#FFC0CB",
    alternate: "#ff8080",
    header: "#ff6666",
  },
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    paddingVertical: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  placeCell: {
    minWidth: 50,
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
  pointsCell: {
    minWidth: 120,
    textAlign: "center",
    fontSize: 14,
  },
  nameCell: {
    minWidth: 150,
    maxWidth: 100,
    fontSize: 14,
    overflow: 'hidden',
  },
  rankCell: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  nameEmailContainer: {
    minWidth: 110,
    maxWidth: 150,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333",
  },
  dateButton: {
    marginHorizontal: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#ddd',
    borderRadius: 5,
  },
  selectedButton: {
    backgroundColor: '#aaa',
  },
  dateText: {
    fontSize: 16,
    color: '#333',
  },
  initialsContainer: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  initialsText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  },
  headerCell: {
    minWidth: 120,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
  },
});

export default Leaderboard;
